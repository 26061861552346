import React, { useEffect, useMemo, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Nav, NavItem, NavLink } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { useClient } from 'hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { toBase64 } from 'components/editors/utils';
import download from 'downloadjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle, faTimes, faTimesCircle, faPencilAlt, faFolder, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NotificationManager } from 'react-notifications';
import DisplayInfo from 'components/common/DisplayInfo';

/**
 * The full image model is used only by the table format listing.
 * 
 * This is an attempt to move components out of ViewPhotographerJobs.jsx (not yet working)
 */

const FullImageModal = ({ previewType, setPreviewModalOpen, previewModalOpen, selectedRow, selectedJob }) => {

    // react hook form stuff
    const methods = useForm({});
    const { watch, register, setValue } = methods;

    const fileWatcher = watch('file')
    const client = useClient();

    // stateful variables
    const [previewImage, setPreviewImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [thumbnails, setThumbnails] = useState([]);

    const largeImageSrc = `data:image/jpg;base64,${previewImage}`;

    function handleFullImageToggle() {
        setPreviewModalOpen(!previewModalOpen);
    }

    const info = useMemo(() => {
        return [
            ['Metadata', selectedRow?.metadata],
            ['Class Name', selectedRow?.class_name],
            ['Name', selectedRow?.full_name],
        ];
    }, [selectedRow]);

    const imgInfo = useMemo(() => {
        return [
            ['File path', selectedImage?.file_path],
            ['Photographer', selectedImage?.photographer__name],
        ];
    }, [selectedImage]);

    useEffect(() => {
        setPreviewImage(null);
        setSelectedImage(null);
        setValue('file', null);
    }, [selectedRow]);

    useEffect(async () => {
        async function load() {
            if (!selectedRow?.metadata) {
                if (!selectedImage) {
                    NotificationManager.info("This image has no metadata");
                }
                setSelectedImage(selectedRow);
                requestFullImage(selectedRow?.file_path, null);
                return [];
            }
            const metadata = selectedRow?.metadata;
            const data = await client.get(`photographer_file_upload/view/${selectedJob?.id}/${metadata}?editing=${previewType}`).get("data");
            return data;
        }
        const thumbnailArr = await load();

        const container = <div className="d-flex">
            <Row className='mt-0 d-flex justify-content-center'>
                {thumbnailArr.map((thumbnail, idx) => (
                    <div key={idx} className='m-3' style={{ width: '175px' }}>
                        <img
                            className={`rounded ${selectedImage?.file_path === thumbnail.file_path ? 'border-8 border-red-500' : ''}`}
                            style={{ width: '175px', height: '175px', objectFit: 'cover' }}
                            src={`data:image/jpeg;base64, ${thumbnail.data}`}
                            onClick={() => { setSelectedImage(thumbnail); requestFullImage(thumbnail.file_path, false) }}
                            role='button'
                        />
                    </div>
                ))}
            </Row>
        </div>
        setThumbnails(container)
    }, [selectedRow, selectedImage])

    const requestFullImage = (path, download_file = false) => {
        client.get(`photographer_job_view/${selectedJob.id}/get_full_photo`, { params: { path: path } }).then((response) => {
            if (download_file) {
                download(
                    'data:application/octet-stream;base64,' + response.data.image,
                    response.data?.name ?? selectedImage?.metadata ?? 'StudentImage'
                );
            } else {
                setPreviewImage(response.data.image);
            }
        });
    };

    async function handleImageUpload() {
        const file = fileWatcher[0];
        const file_encoded = await toBase64(file);

        client
            .post(`photographer_file_upload/upload_individual`, { file: file_encoded, path: selectedImage?.file_path, metadata: selectedImage?.metadata })
            .then((resp) => {
                requestFullImage(selectedImage?.file_path, false);
            })
            .catch((e) => {
                console.error('ERROR: ', e.response.data);
                NotificationManager.error('There was an issue with this request');
                return null;
            });
    }

    return (
        <Modal isOpen={previewModalOpen} toggle={handleFullImageToggle} className='wide-modal'>
            <ModalHeader toggle={handleFullImageToggle} charCode='close' close={<FontAwesomeIcon icon={faTimes} className='' onClick={handleFullImageToggle} role='button' />}>
                {selectedRow?.full_name}
            </ModalHeader>

            <ModalBody>
                <DisplayInfo className='pb-2' values={info} />
                {!!previewImage ? (
                    <>
                        <img className='rounded border' src={largeImageSrc} />
                        <DisplayInfo className='py-2' values={imgInfo} />
                        <Row className='justify-content-between mt-2'>
                            <Col >
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        requestFullImage(selectedImage?.file_path, true)
                                    }}
                                >
                                    Download
                                </Button>
                            </Col>

                            <Col >
                                <div className="justify-content-end d-flex">
                                    <input
                                        type='file'
                                        className='form-control w-75'
                                        id='file'
                                        {...register('file', {
                                            required: false,
                                        })}
                                    />

                                    <Button
                                        className='w-25 ml-2'
                                        color='primary'
                                        onClick={() => {
                                            handleImageUpload();
                                            setValue('file', null);
                                        }}
                                        disabled={!fileWatcher}
                                    >
                                        Replace
                                    </Button>
                                </div>

                                {!!fileWatcher && (
                                    <Row>
                                        <img className='rounded border' src={fileWatcher?.length > 0 ? URL.createObjectURL(fileWatcher[0]) : ''} />
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </>) : <></>}
                <p className="mb-1 mt-5">Please select an image below to preview, download or replace</p>
                {thumbnails}
            </ModalBody>

            <ModalFooter>
                <Row className='justify-content-end'>
                    <Col>
                        <Button
                            onClick={() => {
                                handleFullImageToggle();
                            }}
                        >
                            Close
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
};

export default FullImageModal;
