
import React, { Fragment, useEffect, useMemo, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from "react-query";

// Display a single note.

const Note = ({ note }) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem', marginBottom: '0px', border: '1px solid #ddd', borderRadius: '4px' }}>
        {note.photographer && (
            <div style={{ fontSize: '0.875rem', color: '#666' }}>
                Photographer: {note.photographer.name}
            </div>
        )}

        <div style={{ whiteSpace: 'pre-wrap', paddingLeft: '1rem', borderLeft: '6px solid #aaa' }}>
            {note.note}
        </div>

        <div style={{ fontSize: '0.875rem', color: '#666' }}>
            Posted by {note.created_by?.name || 'Unknown'} - {new Date(note.created_at).toLocaleString()}
        </div>
    </div>
)

// Display a list of notes.

const NotesList = ({ notes }) => (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        {notes.map((note, index) => (
            <Note key={`${note.created_at}-${index}`} note={note} />
        ))}
    </div>
)

const PhotographerNotesModal = ({
    setModelIsOpen,
    folderId,
    client,
}) => {
    // Fetch the notes for the folder.

    const { data: notes, isFetching: isFetchingNotes } = useQuery(
        ['get_notes', folderId],
        async () => {
            const resp = await client.get(`photographer_file_upload/get_folder_notes?folder_id=${folderId}`);
            return resp.data;
        },
        {
            refetchOnWindowFocus: false,
            enabled: folderId !== null,
        }
    );    

    function handleCloseModal() {
        setModelIsOpen(false);
    }

    if (isFetchingNotes) {
        return <Fragment></Fragment>
    }

    return (
        <Modal isOpen={true} toggle={handleCloseModal} style={{ maxWidth: 900, maxHeight: '90vh', margin: '2rem auto' }}>
            <ModalHeader charCode='close' close={<FontAwesomeIcon icon={faTimes} className='' onClick={handleCloseModal} role='button' />}>
                Photographer notes {/*for (job name) and folder (folder name)*/}
            </ModalHeader>

            <ModalBody style={{ maxHeight: 'calc(90vh - 2rem)', overflowY: 'auto' }}>
                <NotesList notes={notes} />
            </ModalBody>
        </Modal>
    );
};

export default PhotographerNotesModal;
