import React, { createContext, useMemo } from "react";

export const ModalContext = createContext();

export const ModalContextProvider = ({ value: valueProp, ...props }) => {
  const { isOpen, setIsOpen, toggle } = valueProp;

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      toggle,
    }),
    [isOpen, setIsOpen, toggle]
  );

  return <ModalContext.Provider value={value} {...props} />;
};

export default ModalContext;
