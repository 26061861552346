import React, { useState, useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Label, Input, Row, Col, Alert } from 'reactstrap';
import { RSubmitButton as SubmitButton, RFormGroup as FormGroup } from 'components/react-hook-form';
import { ModalHeader } from 'components/ModalHeader';
import Cropper from 'react-easy-crop';
import { useClient } from 'hooks';
 
/**
 * Allow the user to either crop or to rotate a photo.
 */

export const PhotoCropModal = ({
	// Visibility state is managed in the parent.
	showCropConfirm,
	setShowCropConfirm,
  cropArea, // String "{width}x{height}" example "5x7"
  selectedCropImage,
  setEnteredCrop,
  imageEditType,
  refreshImageById,
}) =>
{
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [grid, setGrid] = useState(true);
  const [pending, setPending] = useState(false);
  const [fineZoom, setFineZoom] = useState(false);

  const [width, height] = cropArea ? cropArea.split('x') : [null, null];
  const aspect = (cropArea && cropArea != 'None') ? width / height : 5 / 7;
  
  const client = useClient();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log('croppedAreaPixels', croppedAreaPixels);
    // { width: number, height: number, x: number, y: number}
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // The user has selected a rotation by a 90 degree increment.
  function onRotateImage() {
    // Increase rotation by 90 degrees counter-clockwise.
    if (rotation == 0) {
      setRotation(270);
    }
    else if (rotation == 90) {
      setRotation(0);
    }
    else if (rotation == 180) {
      setRotation(90);
    }
    else if (rotation == 270) {
      setRotation(180);
    }
  }

  const onCropSubmit = async (x) => {
    try {
      setPending(true);

      const imageAlter = {
        job: selectedCropImage.image.job_id,
        image_path: selectedCropImage.image.path,
        ...(imageEditType === 'rotate' ? { rotation: rotation } : {}),
        ...(imageEditType === 'crop' ? { cropArea: croppedAreaPixels } : {}),
      };

      await client.put(`photo_adjustment/${imageEditType}`, imageAlter).then(r => {
        setShowCropConfirm(false);
        setEnteredCrop(true);
        setGrid(true);
        setShowCropConfirm(false);
        refreshImageById(selectedCropImage.image.id);
        setCrop({ x: 0, y: 0 }); 
        setRotation(0);
        setZoom(1);
      })

    }
    catch (ex) {
      //console.error('ERROR: ', ex);
      //console.log('ex.message: ', ex.message)
      // NotificationManager.error('There was an issue with this request');
    }
    finally {
      setPending(false);
    }
  }

	return (
		<Modal isOpen={showCropConfirm} className="sm-modal">
			<ModalHeader>
      {imageEditType == 'rotate' ? 'Rotate Image' : 'Crop Image' }
			</ModalHeader>

			<ModalBody>
        <div className="crop-container">
          {imageEditType == 'rotate' ?
            <Cropper
              image={selectedCropImage ? selectedCropImage.imageOutput : null}
              crop={crop}
              rotation={rotation}
              showGrid={false}
              aspect={aspect}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              classes={
                { cropAreaClassName: 'cropper-crop-area' } // Class for the crop area
              }
            />
          :
            <Cropper
              image={selectedCropImage ? selectedCropImage.imageOutput : null}
              crop={crop}
              zoom={zoom}
              zoomSpeed={fineZoom ? 0.05 : 0.5}
              aspect={aspect}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              classes={
                { cropAreaClassName: 'cropper-crop-area-default' } // Class for the crop area
              }
            />
          }
        </div>
			</ModalBody>

			<ModalFooter>
        {imageEditType == 'rotate' && 
          <SubmitButton
            className="rotateButton"
            onClick={onRotateImage}
            color='primary'
            pending={pending}
          >
            ↺ Rotate image
          </SubmitButton>
        }

        <Button
					onClick={() => {setFineZoom(! fineZoom)}}
					color='info'
          outline
          style={{ marginRight: 'auto' }}
				>
					{fineZoom ? 'Fine Zoom' : 'Course Zoom'}
				</Button>

				<Button
					onClick={() => {setShowCropConfirm(false); setZoom(1); setGrid(true); setCrop({ x: 0, y: 0 }); setTimeout(function() { setRotation(0) }, 500);}}
					color='secondary'
				>
					Cancel
				</Button>

        <SubmitButton
					onClick={onCropSubmit}
					color='primary'
          pending={pending}
				>
          {imageEditType == 'rotate' ? 'Apply rotation' : 'Apply crop'}
				</SubmitButton>
			</ModalFooter>
		</Modal>
	);
};
