import React, { useState, useCallback, useRef, useMemo } from "react";
import { Popover } from "reactstrap";
import { uniqueId } from "lodash";

export const usePopover = () => {
  const [isOpen, setIsOpen] = useState();
  const id = useMemo(() => uniqueId("popover_"), []);

  function toggle() {
    setIsOpen(!isOpen);
  }

  const ctx = useRef();
  ctx.current = { toggle, isOpen, target: id };

  const renderPopover = useCallback(
    (props) => <Popover {...ctx.current} {...props} />,
    [ctx]
  );

  return { isOpen, setIsOpen, toggle, id, Popover: renderPopover };
};

export default usePopover;
