import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

export const useSearchParams = () => {
  const { search } = useLocation();
  const value = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [
    search,
  ]);
  return value;
};

export default useSearchParams;
