
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Select, CustomButton, Input, DatePicker, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { useUserContext, useClient } from 'hooks';
import { createClient } from 'hooks/useClient';
import { useQuery, useQueryClient } from 'react-query';
import { DraggableGraphic } from 'components/editors';
import { Page } from 'components/common';
import { toBase64 } from 'components/editors/utils';


import { NotificationManager } from 'react-notifications';


export const PhotographerUploadJobPhotos = () => {

    const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});
	const client = useClient();
	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;


    const [jobOptionsList, setJobOptionsList] = useState([])

	// -------------------------------------------------------------
	// API Stuff
	// -------------------------------------------------------------

    const { refetch: getJobs } = useQuery(
		'get_photographer_job_list',
		async () => {
			client.get(`photographer_job_list`).then((data) => {
				// console.log('response data:', data);
				// setPrintPanels(data.data);

                setJobOptionsList(data.data)

				// const jobs = data.data
				// jobs.forEach((job) => {
				// 	if(job.has_data){
				// 		console.log('job:', job)
				// 	}
				// })

				// setJobOptions(data.data.sort(compare));
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);


    const onSavePressed = async ({ ...all }) => {
		// console.log('Saving Now', all);

		// const panelToSave = all;
		// console.log(panelToSave)

		all.files.forEach(file => {
			console.log("file:", file)
		})

		const payload = {
			job_id: all.just_for_form.selected_job.id
		}

		// return
		client
			.post('/upload_photographer_photos', payload)
			.then((resp) => {
				// togglePreviewModal();
				// console.log(resp.data)
				// setPreviewImage(`data:image/jpeg;base64,${resp.data.file}`);
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				return null;
			});
	};


	// -------------------------------------------------------------
	// Return Component
	// -------------------------------------------------------------

    return (<>
         <Page title="Upload Job Photos">
            <FormProvider {...methods}>
				<FormGroup label={'Select Job'}>
					<Select
						options={jobOptionsList}
						getOptionLabel={(data) => {
							return data['school_name_with_date'];
						}}
						getOptionValue={(data) => {
							return data['id'];
						}}
						{...register('just_for_form.selected_job', {
							rules: {
								required: true,
							},
						})}
						onChange={(selectedJob) => {
							console.log('newValue', selectedJob);
							setValue('just_for_form.selected_job',selectedJob)
							setValue('job_id', selectedJob.id)
						}}
						isLoading={jobOptionsList.length == 0}
						// loadingMessage={'Loading Jobs'}
						shouldUnregister={true}
						defaultValue={''}
					/>
				</FormGroup>

				{!!watch('just_for_form.selected_job') && (
					<>
					<FormGroup key={7} label='Upload Files'>
						<input
							webkitdirectory='true'
							directory='true'
							multiple={true}
							type='file'
							className='form-control'
							id='camera_directory'
							{...register('file_directory', {
								required: false,
								onChange: async (e) => {
									const files = [...e.target.files];
									console.log('files: ', files);
									setValue('files', files);
									// createFilesList();
								},
							})}
						/>
					</FormGroup>

					</>
				)}
				
                <form onSubmit={handleSubmit(onSavePressed)}>
					<Row className="d-flex justify-content-end pr-3">
						
                    <SubmitButton>Upload</SubmitButton>
					</Row>
                </form>

            </FormProvider>
        </Page>
    </>)
};

export default PhotographerUploadJobPhotos;
