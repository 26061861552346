import { useEffect } from "react";

export const useAsyncEffect = (fn, deps) =>
  useEffect(
    () => void fn(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

export default useAsyncEffect;
