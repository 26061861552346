import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { useQuery, useQueryClient } from 'react-query';
import { ModalHeader } from 'components/ModalHeader';
import { toBase64 } from 'components/editors/utils';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { RFormGroup as FormGroup, RichSelect as Select, CustomButton, Input, DatePicker } from 'components/react-hook-form';
import { NotificationManager } from 'react-notifications';
import { RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { useUserContext, useClient } from 'hooks';

import 'react-slideshow-image/dist/styles.css';
import { Page } from 'components/common';
import { Slide } from 'react-slideshow-image';

const TYPES = new Set(["image/jpg", "image/jpeg", "image/tiff"]);

export const CreateNewPanelsBrowse = ({ setSelectedForm }) => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const [isCreatingPanels, setIsCreatingPanels] = useState(false)



	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const {
		data: proofCards,
		error: proofCardsError,
		refetch: getProofCards,
	} = useQuery('get_proof_packs', async () => client.get(`proof_card_designs`).get('data'), {
		refetchOnWindowFocus: false,
	});

	const {
		data: printPacks,
		error: printPanelsError,
		refetch: getPrintPacks,
	} = useQuery('get_print_packs', async () => client.get(`print_packs`).get('data'), {
		refetchOnWindowFocus: false,
	});

	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;

	const watchImages = watch('images');
	const watchPanels = watch('panel');

	const [photoOptions, setPhotoOptions] = useState([]);
	const [files, setFiles] = useState({});
	// const [panelRows, setPanelRows] = useState([]);

	const photoOptionsRef = useRef(null);
	photoOptionsRef.current = photoOptions;

	const createFilesList = (fileList) => {
		// const toReturn = [];

		//const fileList = getValues('images');
		// const files = Array.from(fileList);
		console.log(fileList);

		if (fileList) {
			// const files = Array.from(fileList);
			const filesFromList = [...fileList];
			console.log(filesFromList);

			const optionsToSave = [];

			optionsToSave.push({ name: 'All Photos', id: 0 });
			const filesToSave = {};
			filesFromList.forEach((file, index) => {
				console.log(file);
				// toReturn.push(
				// 	<p>{file.name}</p>
				// 	)
				optionsToSave.push({ name: file.name, id: index + 1 });
				filesToSave[file.name] = file;
			});
			setFiles({ ...filesToSave });
			setPhotoOptions([...optionsToSave]);
		}

		// return toReturn;
	};

	const createNewPanelRows = () => {
		const toReturn = [];

		const panelRows = getValues('panel');
		console.log('panelRows', panelRows);
		panelRows.forEach((panel, index) => {
			toReturn.push(createPanelRow(index));
		});

		return toReturn;
	};

	const createPanelRow = (panelId) => {
		const proofCardVal = getValues(`panel.${panelId}.proof`);
		console.log('proofcardval: ', proofCardVal);

		const chosenImage = getValues(`panel.${panelId}.image`);
		console.log(chosenImage);
		return (
			<>
				<Row>
					<Col md={6}>
						<FormGroup name={`panel.${panelId}.image`} label='Photo'>
							<Select
							labelKey="name"
							valueKey="id"
							name={`panel.${panelId}.image`}
							options={photoOptionsRef.current}
								rules={{ required: true }}
								onChange={async (image) => {
									console.log('e', image);
									if (image.id > 0) {
										// just one image has been selected
										// const tempImage = await toBase64(files[image.name]);
										image.file_name = image.name;
										image.images = [await toBase64(files[image.name])];
									} else {
										// use all images

										const images = [];
										for (const [key, value] of Object.entries(files)) {
											console.log(`${key}`, value);
											images.push(await toBase64(value));
										}
										image.file_name = Object.keys(files);
										image.images = images;
									}
									// image.images = toBase64(files[image.name]);
									console.log('images:', image.images);
									setValue(`panel.${panelId}.image`, image);
								}}
							/>
						</FormGroup>

						<FormGroup name={`panel.${panelId}.proof`} label='Proof Card'>
							<Select
								options={proofCards}
								labelKey="name"
								valueKey="id"
								name={`panel.${panelId}.proof`}
								rules={{ required: true }}
								onChange={(newValue) => {
									console.log('e', newValue);
									setValue(`panel.${panelId}.proof`, newValue);
								}}
							/>
						</FormGroup>

						{proofCardVal && (
							<FormGroup name={`panel.${panelId}.pack`} label='Print Packs'>
								<Select
									isMulti
									labelKey="name"
									valueKey="id"
									options={printPacks}
									name={`panel.${panelId}.pack`}
									rules={{ required: true }}
									onChange={(newValue) => {
										console.log('e', newValue);
										setValue(`panel.${panelId}.pack`, newValue);
									}}
								/>
							</FormGroup>
						)}
					</Col>
					<Col md={6} className='d-flex align-content-center justify-content-center'>
						{createPreviewImage(panelId)}
					</Col>
				</Row>
				<hr />
			</>
		);
	};

	const createPreviewImage = (panelId) => {
		// {getValues(`panel.${panelId}.image.images`) &&
		// (<img src={'data:image/jpeg;base64,' + getValues(`panel.${panelId}.image.images`)[0]} />)}

		const images = getValues(`panel.${panelId}.image.images`);
		console.log('HERE: ', images);

		const defaultReturn = (
			<Row className='d-flex align-content-center justify-content-center'>
				<p className='m-0'>Image Preview</p>
			</Row>
		);

		if (!images) {
			return defaultReturn;
		}

		if (images.length > 1) {
			// Calculate Grid
			const slideElements = [];

			images.forEach((image) => {
				slideElements.push(
					<div className='each-slide-print-panel'>
						<div className='rounded' style={{ backgroundImage: 'url(data:image/jpeg;base64,' + image }}></div>
					</div>
				);
			});

			return (
				<div className='slideshow-container w-100 h-100'>
					<Slide easing='ease'>{slideElements}</Slide>
				</div>
			);
		} else if (images.length == 1) {
			// just 1 image selected
			return <img className="rounded" src={'data:image/jpeg;base64,' + images[0]} />;
		}

		return defaultReturn;
	};

	const getPanelRows = () => {
		const header = (
			<Row>
				<Col>
					<Label>Photo</Label>
				</Col>
				<Col>
					<Label>Proof Card</Label>
				</Col>
				{/* <Col>
					<Label>Print Packs</Label>
				</Col> */}
			</Row>
		);
		const addButton = (
			<Row className='justify-content-center'>
				<Col md={3}>
					<FormGroup>
						<Button
							disabled={!(getValues('panel') ?? 0).length > 0}
							className='w-100'
							onClick={() => {
								console.log('pressed');
								// panelRows.pop();
								// setPanelRows([...panelRows]);
								const panels = getValues('panel');
								unregister(`panel.${Object.keys(panels ?? {}).length ?? 0}`, {});
							}}
							color='danger'
						>
							Remove Panel
						</Button>
					</FormGroup>
				</Col>
				<Col md={3}>
					<FormGroup>
						<Button
							className='w-100'
							onClick={() => {
								const panels = getValues('panel');
								setValue(`panel.${Object.keys(panels ?? {}).length ?? 0}`, {});
							}}
							color='primary'
						>
							Add Panel
						</Button>
					</FormGroup>
				</Col>
			</Row>
		);

		return (
			<>
				{/* {panelRows.length > 0 && header} */}
				{watchPanels && createNewPanelRows()}
				{photoOptions.length > 0 && addButton}
			</>
		);
	};

	const createForm = () => {
		return (
			<FormProvider {...methods}>
				<FormGroup>
					<input
						webkitdirectory='true'
						directory='true'
						multiple={true}
						type='file'
						className='form-control'
						id='images'
						{...register('images', {
							required: false,
							onChange: async (e) => {
								const files = [...e.target.files].filter(v => TYPES.has(v.type));//const files = e.target.files;
								console.log('files: ', files);
								createFilesList(files);
							},
						})}
					/>
				</FormGroup>

				<div>{watchImages && getPanelRows()}</div>
			</FormProvider>
		);
	};

	const onSavePressed = async (all, e) => {
		// console.log('event: ', e);
		setIsCreatingPanels(true)
		console.log('Saving Now', all);

		const panel = all;

		// dont need to send this off
		delete panel.images;

		// console.log("panel itself", panel.panel)

		const data = panel.panel

		const panelsToSave = []

		data.forEach(panel => {
			console.log("This panel: ", panel)
			// panel["notes"] = "";
			// panel["quantity"] = 1;
			const packs = panel["pack"];
			const images = panel["image"]["images"];
			const file_name = panel["image"]["file_name"];
			images.forEach((image, idx) => {
				packs.forEach(pack => {
					console.log(pack)

					const toAdd = {
						notes: "",
						pack_name:pack.name,
						quantity:1,
						price:pack.price,
						pack:pack.id,
						file:image,
						file_name: typeof file_name === "string" ? file_name : file_name[idx],
					}
					panelsToSave.push(toAdd)

				})
			})

		});


		// console.log(panelsToSave);
		// return

		client
			.post('/generate_panels', {job_type: 'PANELS_BROWSE', job_data: panelsToSave})
			.then((resp) => {
				const id = resp.data?.id;
				NotificationManager.success('Panel has been created');
				// setSelectedForm(null);
				navigate(!!id ? `/viewworkflow/${id}` : "/viewworkflow");
				setIsCreatingPanels(false)

				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				setIsCreatingPanels(false)

				return null;
			});
	};

	return (
		<>
			{createForm()}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSavePressed)}>
					<Row className='justify-content-end mt-4'>
						<Col md={2}>
							<FormGroup>
								<Button
									className='w-100'
									onClick={() => {
										setSelectedForm(null);
									}}
									color='danger'
								>
									Cancel
								</Button>
							</FormGroup>
						</Col>
						<Col md={2}>
							<FormGroup>
								<SubmitButton pending={isCreatingPanels} disabled={photoOptions.length === 0 || !watchPanels} className='w-100' onClick={() => {}} color='primary'>
									Create Panels
								</SubmitButton>
							</FormGroup>
						</Col>
					</Row>
				</form>
			</FormProvider>
		</>
	);
};

export default CreateNewPanelsBrowse;
