import React from 'react';
import { useNavigate } from 'react-router';
import { Link, NavLink } from "react-router-dom";
import { NavbarBrand, Nav as _Nav, NavItem, UncontrolledDropdown,
      DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import { useUserContext, useClient } from './hooks';

import download from 'downloadjs';

export const Nav = ({ children }) => {

  const { logout, user } = useUserContext();
  const client = useClient();
  const navigate = useNavigate();
  const onLogout = () => {
    logout();
    navigate('/login');
  };
  const isPhotographer = user.is_photographer;
  const isAdminOrStaff = user.is_superuser || user.is_staff;

  return (
    <div >
      <nav className='justify-content-start' color='light' light expand='sm'>
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        <NavItem className="nav-tabs">
          <NavbarBrand className="heading-a cursor-pointer" onClick={() => navigate("/")}>
            Fraser Portraits
          </NavbarBrand>
          </NavItem>
          {isAdminOrStaff &&
            <>
              <UncontrolledDropdown nav inNavbar className="nav-tabs">
                <DropdownToggle nav caret>
                  Create & Generate
                </DropdownToggle>
                <DropdownMenu end >
                  <DropdownItem tag={Link} to='/createnewpanels'> Create New Panels </DropdownItem>
                  <DropdownItem tag={Link} to='/generatecomposites'> Generate Composites </DropdownItem>
                  <DropdownItem tag={Link} to='/generateproofcards'title="Generate from processed images" > Generate Proof Cards </DropdownItem>
                  <DropdownItem tag={Link} to='/generateproofs'title="Processed images then generate" > Process + Generate Proof Cards </DropdownItem>
                  <DropdownItem tag={Link} to='/startnewjob'> Process Images </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar className="nav-tabs">
                <DropdownToggle className='nav-link-options' nav caret>
                  Settings And Presets
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={Link} to='/createnewproofcard'> Create New Proof Card</DropdownItem>
                  <DropdownItem tag={Link} to='/printpanels'> Create New Print Panels</DropdownItem>
                  <DropdownItem tag={Link} to='/createnewprintpacks'> Create New Print Packs</DropdownItem>
                  <DropdownItem tag={Link} to='/createstaffphototemplate'> Create Staff Photo Template</DropdownItem>
                  <DropdownItem tag={Link} to='/assignprintpackstoproofcard'> Assign Packs To Proof Card Preset</DropdownItem>
                  <DropdownItem tag={Link} to='/autoimportsystempresets'> Auto Import System Presets</DropdownItem>
                  <DropdownItem
                    onClick={async () => {
                      client.get(`get_pack_barcodes`).then((resp) => {
                        console.log(resp);
                        const contentType = resp.headers['content-type'];
                        if (contentType === 'application/pdf') {
                          download(resp.data, `barcodes.pdf`, 'application/pdf');
                        }
                      });
                    }}
                  >
                    Download Pack Barcodes
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem className="nav-tabs">
                <NavLink className="nav-link" to='/processstaffimagejobs'>
                  Process Staff Image Jobs
                </NavLink>
              </NavItem>
              <NavItem className="nav-tabs">
                <NavLink className="nav-link" to='/processorders'>
                  Process Orders
                </NavLink>
              </NavItem>
              <NavItem className="nav-tabs">
                <NavLink className="nav-link" to='/viewworkflow'>
                  View Workflow
                </NavLink>
              </NavItem>
            </>
          }
          {
            (isAdminOrStaff || isPhotographer) &&
            <>
              <NavItem className="nav-tabs">
                <NavLink className="nav-link" to='/viewphotographerjob'>
                  View Jobs
                </NavLink>
              </NavItem>
              <NavItem className="nav-tabs">
                {/* <NavLink className="nav-link" to='/photographerstartjob'>
                  Start Job
                </NavLink> */}
              </NavItem>
            </>
          }
          <UncontrolledDropdown nav inNavbar className="nav-tabs">
            <DropdownToggle className='nav-link-options' nav caret>
              Options
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={onLogout}> Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        </nav>
      <div id='allChildren'>{children}</div>
    </div>
  );
};

export default Nav;
