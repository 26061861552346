import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

export const BooleanIcon = ({ test, showFalsey = true }) => {
  if (!test && !showFalsey) return null;
  return (
    <FontAwesomeIcon
      icon={test ? faCheckCircle : faTimesCircle}
      className={test ? "text-green-500" : "text-red-500"}
    />
  );
};

export default BooleanIcon;
