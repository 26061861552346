import React, { useEffect, useMemo, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import {Gallery} from 'react-grid-gallery';
import { ThumbnailCaption } from 'Pages/ViewPhotographerJobs/DividedGallery';

/**
 * The read-only gallery sets the thumbnail caption to display-only elements.
 * A copy is made of the images with the thumbnail caption that the main
 * gallery added being replaced.
 */
const GalleryReadonly = ({ images, ...props }) => {
    // Create copies of images with simplified captions.
    const processedImages = images.map(img => ({
        ...img,
        thumbnailCaption: <ThumbnailCaption photoRecord={img} allowEdit={false} />
    }));

    return <Gallery images={processedImages} {...props} />;
}

export default GalleryReadonly;
