import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams, Link } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { Pagination, Table, Search, useListQuery, useListTable, TableContextProvider, useClientPaginatedRowSelectTable, DateTimeCell } from '../components/common/Tables';
import { useUserContext, useClient } from 'hooks';
import { FetchingSpinner } from 'components/common';
import { RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { Page } from 'components/common';
import classNames from 'classnames';
import moment from 'moment';
import download from 'downloadjs';
import { NotificationManager } from 'react-notifications';
import DisplayInfo from 'components/common/DisplayInfo';
import useInterval from 'hooks/useInterval';
import ProgressBarRich from '../components/common/ProgressBarRich';

const getColor = (row) => {
  const status = row.original.status;
  const failed = row.original?.failed;
  const processed = row.original?.processed;
  if (status === "Failed" ||processed === 0) return "red";
  else if (failed > 0 && processed > 0) return "orange";
  else if (failed === 0 && status == "Complete") return "green";
  else return "black";
}

const columns = [
  {
    Header: "Time added",
    accessor: "processed_at",
    Cell: DateTimeCell,
    cellClassName: "whitespace-no-wrap",
    minWidth: 75,
  },
  {
    Header: "Job",
    accessor: "job.job_number",
  },
  {
    Header: 'Name',
    accessor: 'job.name',
    Cell: ({ row }) => {
      // console.log('row', row.original)
      const staff_image_job = row.original?.staff_image_job;
      const jobType = row.original?.job_type
      const job             = row.original?.job;
      let name = row.original?.job_name
      if (staff_image_job) {
        name = `${staff_image_job?.school_name}: ${staff_image_job?.name}`
      }
      if ((jobType == "PROOF_CARD" || jobType == "PROOF_SHARED")) {
        name = job.school_name_with_date
      }

      // name = staff_image_job ? `${staff_image_job?.school_name}: ${staff_image_job?.name}`
      //             : (jobType == "PROOF_CARD" || jobType == "PROOF_SHARED") ? job.school_name_with_date :  job?.name ? `${job?.name}` : `${row.original?.type}`;
      return <div>{name}</div>
    }
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    // accessor: 'status',
    Cell: ({ row }) => {

        const status = row.original?.status;
        return <div>{status} {status == "Running" ? <FetchingSpinner className='mx-2' isFetching={true} /> : <></>} </div>;

    },
  },
  {
    Header: 'Detail',
    Cell: ({ row }) => {
      const failed = row.original.failed;
      const processed = row.original.processed;
      return <div>{`${processed}/${failed + processed}`}</div>;
      if (row.original.job_complete_data && row.original.job_complete_data.Report) {
        const report = row.original.job_complete_data.Report;
        return <div> {`${report.processed}/${report.processed + report.failed}`} </div>;
      } else {
        return <div>{`${row.original.processed_files}/${row.original.total_files}`}</div>;
      }
    },
  },
];

const secondaryColumns = [
  {
    Header: "Error",
    accessor: "error",
  },
  {
    Header: "File",
    accessor: "file",
  },
];

const DetailModal = ({ info, isOpen, toggle, closeModal, selectedJob, handleOnClick, downloadingZip, downloadProgress }) => {

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  }

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!selectedJob) setData([]);
    setData(selectedJob?.failed_list || []);
  }, [selectedJob]);

  const { table } = useClientPaginatedRowSelectTable({
    data,
    setData,
    initialState: {
      pageSize: 10
    },
    columns: useMemo(() => secondaryColumns, [secondaryColumns]),
    selectionColumn: false
  });

  return (
      <Modal isOpen={isOpen} toggle={toggle} className='md-modal' onClosed={() => {
        navigate("/viewworkflow");
      }}>
        <ModalHeader>
          {capitalizeFirstLetter(selectedJob?.job_type?.replace("_", " "))} job
        </ModalHeader>

        <ModalBody>
          <TableContextProvider table={table}>
            <DisplayInfo values={info} />
            {selectedJob ? (
              <>
                <Row className='border-b pb-2 mb-3'>
                  <Col>
                    <div className="text-2xl mb-2 font-bold">Processed</div>
                    <div className="text-lg ml-2 text-gray-900">{selectedJob.processed}</div>
                  </Col>
                  <Col>
                    <div className="text-2xl mb-2 font-bold">Failed</div>
                    <div className="text-lg ml-2 text-gray-900">{selectedJob.failed}</div>
                  </Col>
                </Row>
                {selectedJob.failed > 0 ? (
                  <>
                    <Table clientSidePagination size="sm" striped />
                    <Pagination />
                  </>
                  )
                  : <div className="text-lg mt-2 text-gray-500">
                    No errors
                  </div>
                }

              </>
            ) : (
              <div className="text-lg mt-2">
                No report available for this job
              </div>
            )}
          </TableContextProvider>

          {downloadingZip &&
          <div className='py-2'>
            <ProgressBarRich value={downloadProgress} striped max={100} />
          </div>
          }
        </ModalBody>

        <ModalFooter>
          <Row>
            <div className="col-auto">
              <Button
                color="danger"
                className='w-100'
                onClick={closeModal}
              >
                Close
              </Button>
            </div>
            <div className="col-auto">
              <SubmitButton color="primary" className='w-100' onClick={handleOnClick} pending={downloadingZip} disabled={downloadingZip || selectedJob?.status == "Running"}>
                Download Processed Files
              </SubmitButton>
            </div>
          </Row>
        </ModalFooter>
      </Modal>
      
  );
};

export const ViewWorkflow = () => {
  const client = useClient();
	const navigate = useNavigate();

  const url = 'photo_workflow';
  const [selectedJob, setSelectedJob] = useState({});
  const [isDownloadingZip, setIsDownloadingZip] = useState(false)
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const toggleDetailModal = () => {
    setDetailModalOpen(x => !x);
  };
  const [downloadProgress, setDownloadProgress] = useState(0);
  async function loadJobData(id) {
    const job = await client.get(`${url}/${id}`).get("data");
    setSelectedJob(job);
    toggleDetailModal();
  }

  const { id } = useParams();
  useEffect(() => {
    async function load() {
      if (id) {
        try {
          loadJobData(id);
        } catch (ex) {
          const detail = ex?.response?.data?.detail;
          NotificationManager.error(detail ?? "Something went wrong when trying to load photo job data");
        }
      }
    }
    load();
  }, [id]);




  const [data, setData] = useState([]);

  const secondaryData = useMemo(() => {
    if (!selectedJob) return [];
    return selectedJob?.failed_list || [];
  }, [selectedJob]);
  const closeModal = () => {
    setSelectedJob(null);
    // toggleDetailModal();
    setDetailModalOpen(false); //
    navigate('/viewworkflow')
  };
  const table = useListTable({
    columns,
    data,
    initialState: {
      pageSize: 20,
    },
  });

  const info = useMemo(() => {
    const staff_image_job = selectedJob?.staff_image_job;
    const jobType = selectedJob?.job_type
    const job = selectedJob?.job;
    let name = selectedJob?.job_name;

    if (staff_image_job) {
      name = `${staff_image_job?.school_name}: ${staff_image_job?.name}`;
    }

    if ((jobType == "PROOF_CARD" || jobType == "PROOF_SHARED")) {
      name = job.school_name_with_date;
    }

    // staff_image_job ? `${staff_image_job?.school_name}: ${staff_image_job?.name}`
    //               : (jobType == "PROOF_CARD" || jobType == "PROOF_SHARED") ? job.school_name_with_date :  job?.name ? `${job?.name}` : `${selectedJob?.type}`;

    // Make the job number a link to the job detail page.
    
    let job_number_element = '-';

    if (selectedJob?.job) {
      job_number_element = <Link to={`/viewphotographerjob/${selectedJob?.job?.id}`}>{selectedJob?.job?.job_number}</Link>;
    }

    let info_list = [
      ["Name", name],
      ["Type", `${selectedJob?.type}`],
      ["Job Number", job_number_element],
      ["School", `${selectedJob?.job?.school__name ?? '-'}`],
      ["Date", `${moment(selectedJob?.job?.date).format("DD/MM/YYYY")}`],
      ["Status", ["Running", "Uploading", "Pending", "Queued"].includes(selectedJob?.status) ? <Row><Col md={3}>{selectedJob?.status?.replace("_", " ")}</Col><Col><ProgressBarRich animated={true} value={jobType?.includes("PANELS") ? selectedJob?.processed_files * 100 : Math.floor(selectedJob?.processed_files / selectedJob?.total_files * 100)} striped max={100} /></Col></Row> : <>{selectedJob?.status?.replace("_", " ")}</> ],
      ["Progress", jobType?.includes("PANELS") ? (<div>{`${selectedJob?.processed_files * 100}%`}</div>):(<div>{`${selectedJob?.processed_files}/${selectedJob?.total_files}`}</div>)],
      ["Processed at", `${moment(selectedJob?.processed_at).format("DD/MM/YYYY HH:mm:ss")}`],
      ["# processed", `${selectedJob?.processed ?? '-'}`],
      ["# failed", `${selectedJob?.failed ?? '-'}`],
    ];

    if (selectedJob?.job_data?.crop?.crop) {
      info_list = info_list.concat([
        ["Crop DPI", `${selectedJob?.job_data?.crop?.dpi}`],
        ["Crop Width", `${selectedJob?.job_data?.crop?.width}`],
        ["Crop Height", `${selectedJob?.job_data?.crop?.height}`],
        ["Crop Format", `${selectedJob?.job_data?.crop?.format}`],
      ]);
    }

    return info_list;
  }, [selectedJob]);

  const { } = useQuery('get_jobs', async () => client.get(`skylab/get_jobs`, {params:{id: selectedJob.job_data.id, update: true, photo_job_id: selectedJob.id}}).then((resp) =>{
    reloadJobData(id);
	}), {
		refetchOnWindowFocus: false,
		enabled: selectedJob?.job_type == "SKYLAB" && ["Running", "Pending", "Uploading", "Queued", "Downloading", "Pending_Download"].includes(selectedJob?.status),
    refetchInterval: 5000
	})

  const getRowProps = (row) => {
    const color = getColor(row);
    return {
      onClick: async () => {
        try {
          // loadJobData(row.original.id);
          navigate(`/viewworkflow/${row.original.id}`);
        }
        catch (ex) {
          const detail = ex?.response?.data?.detail;
          NotificationManager.error(detail ? detail : 'Something went wrong when trying to load job results');
        }

      },
      style: { cursor: 'pointer', color: color},
    };
  };

  // Refresh the query results every five seconds.
  const { isFetching, refetch } = useListQuery({
    setData,
    table,
    onSuccess:() => {
			setTimeout(() => {
				refetch();
			}, 5000);
		},
    url: url,
    query_arguments:{},
  });

  async function reloadJobData(id) {
    try {
      const job = await client.get(`${url}/${id}`).get("data");
      setSelectedJob({...job});
      setDetailModalOpen(true);
    } catch (error) {
      // 
    }
  }

  useInterval(() => {
    if (id && ["Running", "Uploading", "Pending", "Queued"].includes(selectedJob?.status)) {
      reloadJobData(id);
    }
  }, 5000);

  const handleOnClick = async () => {
      console.log(selectedJob)
      setIsDownloadingZip(true);
      const url = `photo_workflow/${selectedJob.id}/result_zip`
    try {
        const data = await client.get(url,
          { timeout: 1000 * 60 * 60 * 2, // 2h timeout as large downloads were taking 8 minutes+ for FP
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
            if (setDownloadProgress) setDownloadProgress(Math.floor(progressEvent.loaded / progressEvent.total * 100))
          }
        }).get("data");
        console.log("Gotten Data")
        download(data,  `${selectedJob?.id || 'output'}.zip`, 'application/zip');
    } catch (e) {
      const detail = e?.response?.data?.detail;
      console.log("detail: ", detail)
      console.log("error: ", e)
      console.log("status: ", e.status)
      console.error('e: ', e, detail);
      NotificationManager.error(detail ? detail : 'Something went wrong when trying to load job results');
    }
    finally {
      setDownloadProgress(0);
      setIsDownloadingZip(false)
    }
  };

  return (
    <>
      <Page>
        <div className='font-bold text-2xl tracking-wide mb-3'>
          View Workflow <FetchingSpinner className='mx-2' isFetching={isFetching} />{' '}
        </div>

        <TableContextProvider table={table}>
          <Row form>
            <Col>
              <Search placeholder='Search' />
            </Col>
          </Row>
          <Table size="sm" striped getRowProps={getRowProps} />
          <Pagination />
        </TableContextProvider>
      </Page>

      <DetailModal
        info={info}
        isOpen={detailModalOpen}
        toggle={toggleDetailModal}
        selectedJob={selectedJob}
        closeModal={closeModal}
        handleOnClick={handleOnClick}
        downloadingZip={isDownloadingZip}
        downloadProgress={downloadProgress}
        setDownloadProgress={setDownloadProgress}
      />
    </>
  );
};

export default ViewWorkflow;
