import React, { useState, useEffect } from 'react';
import { RichSelect as Select } from 'components/react-hook-form';
import { Button, Modal, ModalBody, ModalFooter, Label, Input, Row, Col, Alert } from 'reactstrap';
import { useClient } from 'hooks';
import { useQuery } from 'react-query';
import { toBase64 } from 'components/editors/utils';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { RSubmitButton as SubmitButton, RFormGroup as FormGroup } from 'components/react-hook-form';
import ProgressBarRich from 'components/common/ProgressBarRich';
import {captureException, withScope} from "@sentry/react";
import { ModalHeader } from 'components/ModalHeader';

/**
 * Component to upload photos for a photographer, run as a modal
 * from a number of places in the admin area.
 */

const UPLOAD_CHUNK_MB = 25;
const UPLOAD_CHUNK_SIZE = 10;
const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const PhotographerUploadModal = ({
	// Visibility state is managed in the parent.
	showUploadConfirm,
	setShowUploadConfirm,
	jobId,
	jobNumber,
	afterUploadSuccess,
	// TODO Do we need to pass specificFolder back to the parent?
	setSpecificFolder,
	specificFolder = null,
	folderType = 'editing',
	// TODO: this list does not need to be managed outside of this component, nor fed back to the parent.
	// setAvailableFolders,
	// availableFolders = []
}) =>
{
	// Every time the component is made visible, dispatch the folder list fetch.
	// These four functions achieve that.

	// TODO: if a folder is selected, then set the "defined folder" to upload into.
	// TODO: if the defined folder is set, then change the dropdown to that value.

	// stateful variables
	const [isEncoding, setIsEncoding] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [completed, setCompleted] = useState(0);
	const [numChunks, setNumChunks] = useState(-1);

	const [errorText, setErrorText] = useState(null);

	const [availableFolders, setAvailableFolders] = useState([])
	const [folderSelection, setFolderSelection] = useState([])

	// Fetch the folder names each time the visibility state changes to "true".
	useEffect(() => {
		if (showUploadConfirm) {
			// fetchFolders();
			fetchFolderNames();
		}
	}, [showUploadConfirm]);

	const fetchFolderNames = async () => client.get(`photographer_file_upload/get_folder_tabs?job_id=${jobId}&folder=${folderType}`).then((resp) => {
		const folderNames = Object.values(resp.data).map((object)=>{return object.name})
		// console.log('folderNames', folderNames)
		setAvailableFolders(folderNames);
		// console.log('specificFolder', specificFolder)
	});

	// react hook form stuff
	const methods = useForm({
		defaultValues: {
			clear: false,
			replace: false,
			for_sims: false,
			shouldUnregister: true,
		}
	});

	const { watch, setValue, handleSubmit, register, getValues } = methods;

	const client = useClient();

	// Create the folder selection list from the list of folders.
	useEffect(() => {
		// Format the list of available folders to key/value pairs for the select component.
		setFolderSelection(
			availableFolders.map((value) => ({'id': value, 'name': value}))
		);
		setValue("selected_folder", specificFolder ? specificFolder : "<none>");
	}, [availableFolders]);
	
	// api requests
	const {
		data: photographers,
		error: photographersError,
		refetch: getPhotographers,
	} = useQuery('photographers', async () => client.get(`photographers`).get('data'), {
		// enabled:false,
		refetchOnWindowFocus: false,
	});

	// console.log('photographers', photographers)

	const processPhoto = async (file, selected_folder) => {
		const encoded = await toBase64(file)
		var path = file.webkitRelativePath;

		// remove the folder that was uploaded, we only want its contents
		const pathArr = path.split('/');
		var uploadFolder = pathArr.shift()

		// Form items. Note: we cannot actually read form items from this async function, so don't try.
		// const isGroup = getValues('is_group')
		const isGroup = false

		// The form selection can override the prop value.
		// The specificFolder state when this async function runs is a bit suspect IMO.
		const selectedUploadFolder = selected_folder && selected_folder != "<none>" ? selected_folder : specificFolder;

		// console.log("selectedUploadFolder", selectedUploadFolder, "selected_folder", selected_folder, "specificFolder", specificFolder, "path", path)

		// TODO we want to check specificFolder or the selected form folder.
		if (!isGroup && (selectedUploadFolder == "" || selectedUploadFolder == null) && (folderType == "editing" || folderType == "original")) {
			// make sure to find the 'Upload' folder

			while (uploadFolder != 'upload' && pathArr.length > 0) {
				uploadFolder = pathArr.shift()
			}
		}

		path = pathArr.join('/');

		if (selectedUploadFolder) {
			path = selectedUploadFolder + '/' + path
		}

		// console.log("path for upload--------------", path)

		// const sizeInMB = getObjectSize(encoded)

		return {
			file: encoded,
			path: path,
			name: file.name,
			// fileSize: sizeInMB,
		};
	};

    const clearChecked = watch('clear');

	const getSizeInBytes = obj => {
		let str = null;
		if (typeof obj === 'string') {
		  // If obj is a string, then use it
		  str = obj;
		} else {
		  // Else, make obj into a string
		  str = JSON.stringify(obj);
		}
		// Get the length of the Uint8Array
		const bytes = new TextEncoder().encode(str).length;
		return bytes;
	};

	function getSizeInMB(file) {
		return file.size / (1024 * 1024);
	}

	async function uploadChunk(chunk, index, photographer, job, replace, clear, folderType='editing', backupPresent = false, isGroup = false, folderName = "", forSims = false) {
		try {
			// FormData based uploading does not work on Firefox for some reason with multiple chunks
			// and errors out after a few are sent through.
			// so just send the images base64 encoded
			var respData
			const payload = {
				files: chunk,
				photographer: photographer,
				job: job,
				folderType: folderType, 
				replace: replace,
				firstChunk: index == 0,
				backupPresent: backupPresent,
				isGroup: isGroup,
				folderName: folderName,
				forSims: forSims
			};
			if (index == 0) {
				payload.clear = clear;
			}
			await client.post(`photographer_file_upload/upload`, payload).then(
				resp => {
					respData = resp.data;
				}
			)
			return {count: chunk.length, backupAlreadyPresent: respData.backupAlreadyPresent, failed: false};
		}
		catch (ex) {
			NotificationManager.error("There was an issue with uploading the files in chunk index " + index);
			console.error('ex: ', ex)
			console.error('ex: ', ex.response.data)
			captureException(ex, {status: ex.response.status, data: ex.response.data, level: 'error'});
			return {count: chunk.length, backupAlreadyPresent: backupPresent, failed: true};
		}
	}

  	async function uploadFiles(chunks, photographer, job, replace, clear, folderType = 'editing', isGroup = false, folderName = "", forSims = false, selected_folder = "") {
		var oneFailed = false;
		try {
			var backupPresent = false;
			for (const chunkIdx in chunks) {
				const encodedChunk = await Promise.all(chunks[chunkIdx].map((file) => processPhoto(file, selected_folder)));
				const {count, backupAlreadyPresent, failed} = await uploadChunk(encodedChunk, chunkIdx, photographer, job, replace, clear, folderType, backupPresent, isGroup, folderName, forSims)
				backupPresent = backupAlreadyPresent
				if (!oneFailed) {oneFailed = failed}
				setCompleted(v => v + count);
				await delay(1000);
			}
		}
		catch (ex) {
			captureException(ex)
			console.log(ex)
			NotificationManager.error("There was an issue with uploading the files");
		}
		finally {
			// console.log("Completed", oneFailed)
			if (oneFailed == false) {
				NotificationManager.success("All files uploaded successfully");
				afterUploadSuccess();
			}
			setIsSubmitting(false);
			setValue("is_group", false);
			setValue("selected_folder", "<none>");
			setShowUploadConfirm(false);

			if (specificFolder) {
				setSpecificFolder(null);
			}

			if (availableFolders) {
				setAvailableFolders([]);
			}
		}
	}

	async function onUploadSubmit(formData) {
    	const { photographer, clear, replace, files, for_sims, selected_folder } = formData;

		setIsEncoding(true);

		//const encodedFiles = await Promise.all(Object.values(files).map(processPhoto));
		const encodedFiles = Object.values(files).sort((a, b) => a.size > b.size);

		// don't try to upload hidden files
		var filteredFiles =  encodedFiles.filter(function (el) {return !el.name.startsWith(".");});

		const isGroup = watch('is_group')

		// Upload to the override folder selected by the user, or the folder supplied
		// by the parent, or none (specified within the directory structure).

		// console.log('selected_folder', selected_folder)
		const selectedUploadFolder = (selected_folder && selected_folder != "<none>") ? selected_folder : specificFolder;

		// console.log('specificFolder', specificFolder);

		if (!isGroup && !selectedUploadFolder && (folderType == "editing" || folderType == "original")) {
			// Files are not grouped, so filter out any files that do not contain "upload" somewhere in their path.
			filteredFiles = filteredFiles.filter(function (el) {
				if (!el.webkitRelativePath.includes('upload')) {
					return false;
				}
				return true
			});
		}

		// console.log('filtered files:', filteredFiles)

		setIsEncoding(false);

		if (filteredFiles.length == 0) {
			setErrorText(`No applicable files were found in the selected folder, please ensure that the folder is named or contains a folder named "upload" or select a folder to upload all images to.`);
			console.error(errorText);
			return;
		} else {
			setErrorText(null);
		}

		const chunks = []
		let currentChunk = [];
		let chunkSize = 0 // in MB
    	let chunkLength = 0;

		filteredFiles.forEach((file) => {
			const size = getSizeInMB(file);
			if (chunkSize + size < UPLOAD_CHUNK_MB && chunkLength < UPLOAD_CHUNK_SIZE) {
				currentChunk.push(file)
				chunkSize += size;
				chunkLength++;
			} else {
				// move onto next chunk
				chunks.push(currentChunk)
				currentChunk = [file]
				chunkSize = size;
				chunkLength = 1;
			}
		})
		// add the final chunk
		chunks.push([...currentChunk])

		setNumChunks(filteredFiles.length);
		setIsSubmitting(true);
		setCompleted(0)

		// console.log(chunks)
		var folderName = ""
		if (chunks.length > 0) {
			let chk = chunks[0]
			if (chk.length > 0){
				var path = chk[0].webkitRelativePath;
				// remove the folder that was uploaded, we only want its contents
				const pathArr = path.split('/');
				folderName = pathArr.shift();
			}
		}
		// console.log("folderName: ", folderName)

    	uploadFiles(chunks, photographer.id, jobId, replace, clear, folderType, isGroup, folderName, for_sims, selected_folder);

    	setValue("replace", false);
		setValue("clear", false);
		setValue("selected_folder", "<none>");

    	// send off the data (this is done recursively for each chunk)
		// const totalFileCount = encodedFiles.length
		//sendPhotographerPostRequest(chunks, totalFileCount,  photographer.id, jobId, replace, clear, 0, 0, originalOrEditing=originalOrEditing)
	};

	return (
		<Modal isOpen={showUploadConfirm} className="sm-modal">
			<ModalHeader>
				{ specificFolder != null && <>Upload photos to {specificFolder}</> }
				{ specificFolder == null && <>Upload photos to job {jobNumber}</> }
			</ModalHeader>

			<ModalBody>
				<FormProvider {...methods}>
        			<form onSubmit={handleSubmit(onUploadSubmit)}>
						<FormGroup name="photographer" label="Photographer who took the photos">
							<Select
								name="photographer"
								className="mb-2"
								labelKey="name" 
								valueKey="id"
								options={photographers}
								rules={{ required: true }}
							/>
						</FormGroup>

						<FormGroup name="files">
							<Label htmlFor="files" className="form-group__label">
								Please select files to be uploaded to {specificFolder != null ? specificFolder : "this job"}
							</Label>
							{/* Native form item, so must be registered. */}
							<input
								webkitdirectory='true'
								directory='true'
								multiple={true}
								type='file'
								className='form-control mb-2'
								id='files'
								{...register('files', {
									required: true,
								})}
							/>
						</FormGroup>

						<Col className="pt-2">
            				<FormGroup name="clear">
              					<Input name="clear" type="checkbox" id="clear"
									onChange={(e) => {
										const v = e.target.checked;
										setValue("clear", v);
									}}
									defaultChecked={ getValues('clear') }
								/>
								<Label check htmlFor="clear">
									Clear existing files (removes all files by this photographer from the folder)
								</Label>
            				</FormGroup>

							<FormGroup name="replace">
								<Input name="replace" type="checkbox" id="replace"
									disabled={!!clearChecked} onChange={(e) => {
										const v = e.target.checked;
										setValue("replace", v);
									}}
									defaultChecked={ getValues('replace') }
								/>
								<Label check htmlFor="replace">
									Overwrite existing files
								</Label>
							</FormGroup>

							<FormGroup name="for_sims">
								<Input name="for_sims" type="checkbox" id="for_sims"
									onChange={(e) => {
										const v = e.target.checked;
										setValue("for_sims", v);
									}}
									defaultChecked={ getValues('for_sims') }
								/>
								<Label check htmlFor="for_sims">
									Tag photos for school information management system
								</Label>
							</FormGroup>

							{/* Disabled, and should be removed later. */}
							{false && specificFolder == null && <>
							<FormGroup name="is_group">
								<Input
									should
									type='checkbox'
									id="is_group"
									name={`is_group`}
									rules={{ required: false }}
									onChange={(e) => {
										const v = e.target.checked;
										setValue("is_group", v);
									}}
								/>
								<Label check htmlFor="is_group">
									Group Folder?
								</Label>
							</FormGroup>
							</>}
						</Col>

						{availableFolders.length > 0 &&
						<FormGroup name="selected_folder" label="Destination folder">
							<Select
								name="selected_folder"
								className="mb-2"
								labelKey="name" 
								valueKey="id"
								simpleValue
								defaultValue="<none>"
								options={folderSelection}
								clearable={false}
								rules={{ required: false }}
							/>
						</FormGroup>}
          			</form>
				</FormProvider>

				{ isSubmitting && <ProgressBarRich value={completed} color="darker" max={numChunks} 
            label={`${completed} out of ${numChunks} files`} />}

				{errorText && <Alert color="danger" >{errorText}</Alert>}

			</ModalBody>

			<ModalFooter>
				<Button
					onClick={() => setShowUploadConfirm(false)}
					color='secondary'
				>
					Cancel
				</Button>

				<SubmitButton
					onClick={handleSubmit(onUploadSubmit)}
					color='primary'
					disabled={isEncoding ?? isSubmitting}
					pending={isSubmitting}
				>
					Upload
				</SubmitButton>
			</ModalFooter>
		</Modal>
	);
};
