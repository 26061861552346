
import React, { Fragment, useEffect, useMemo, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from "react-query";
import { useForm, FormProvider } from 'react-hook-form';
import { RFormGroup as FormGroup, RSubmitButton, Input } from 'components/react-hook-form';
import { Row, Col, Label, Alert, Button } from 'reactstrap';
import Toggle from 'react-toggle'

const EditDataModal = ({
    setModelIsOpen,
    selectedJob,
    // photoId,
    // handlePhotoUpdated,
    metadata,
    handleDataUpdated,
    client,
    doSuccessMessage,
    doErrorMessage,
}) => {
    const [error, setError] = useState(null);

    // Fetch the details for the file.

    const { data: photoData, isFetching: isFetchingPhotoData } = useQuery(
        ['get_photo_data', metadata],
        async () => {
            const resp = await client.get(`photographer_file_upload/student_csv_data?job_id=${selectedJob.id}&metadata=${metadata}`);
            return resp.data;
        },
        {
            refetchOnWindowFocus: false,
            enabled: metadata !== null,
        }
    );

    // Create the form.

    const formMethods = useForm({
        defaultValues: {},
        mode: 'all',
    });

    const { watch, setValue, reset, formState, handleSubmit, register, getValues } = formMethods;

    // Initialize form when photoData is available.

    useEffect(() => {
        if (photoData) {
            // Reset the form with photoData values
            reset({
                first_name: photoData.first_name || '',
                surname: photoData.surname || '',
                class_name: photoData.class_name || '',
                metadata: photoData.metadata || '',
                // active: photoData.active || false,
                // for_sims: !!photoData.for_sims || false,
            });
        }
    }, [photoData, reset]);

    function handleCloseModal() {
        setModelIsOpen(false);
    }

    const onSavePressed = async (formData, e) => {
        try {
            setError(null); // Clear any previous errors
            
            // You could add loading state here if needed
            // setIsSubmitting(true);
            
            // Make the PUT request to update the photo data
            const response = await client.put(
                `photographer_file_upload/student_csv_data?job_id=${selectedJob.id}&metadata=${metadata}`,
                formData
            );
            
            // If successful, close the modal or show success message
            if (response.status === 200) {
                doSuccessMessage('Photo details have been updated');
                handleCloseModal();
            }
        } catch (err) {
            // Handle any errors that might occur
            console.error('Error updating photo data', err);
            setError(err.response?.data?.message || 'An error occurred while saving data');
        } finally {
            // setIsSubmitting(false); // Reset loading state
            // handlePhotoUpdated(photoId, formData);
            handleDataUpdated(metadata, formData);
        }

        // handleCloseModal();
    };

    if (isFetchingPhotoData) {
        return <Fragment></Fragment>
    }

    return (
        <Modal isOpen={true} toggle={handleCloseModal} style={{ maxWidth: 900, maxHeight: '90vh', margin: '2rem auto' }}>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSavePressed)}>

                    <ModalHeader charCode='close' close={<FontAwesomeIcon icon={faTimes} className='' onClick={handleCloseModal} role='button' />}>
                        Edit Photo Data
                    </ModalHeader>

                    <ModalBody style={{ maxHeight: 'calc(90vh - 2rem)', overflowY: 'auto' }}>
                        {error && (
                            <Row>
                                <Alert className='w-100' color='danger'>
                                    {error}
                                </Alert>
                            </Row>
                        )}

                        <FormGroup name='first_name' label='First Name'>
                            <Input
                                type='text'
                                className='form-control'
                                name='first_name'
                                rules={{
                                    required: false,
                                    maxLength: { value: 200, message: "Must be at most 200 characters" },
                                }}
                            />
                        </FormGroup>

                        <FormGroup name='surname' label='Last Name'>
                            <Input
                                type='text'
                                className='form-control'
                                name='surname'
                                rules={{
                                    required: false,
                                    maxLength: { value: 200, message: "Must be at most 200 characters" },
                                }}
                            />
                        </FormGroup>

                        <FormGroup name='class_name' label='Class Name'>
                            <Input
                                type='text'
                                className='form-control'
                                name='class_name'
                                rules={{
                                    required: false,
                                    maxLength: { value: 200, message: "Must be at most 200 characters" },
                                }}
                            />
                        </FormGroup>

                        <FormGroup name='metadata' label='Metadata/School ID'>
                            <Input
                                type='text'
                                className='form-control'
                                name='metadata'
                                disabled
                                rules={{
                                    required: false,
                                    maxLength: { value: 200, message: "Must be at most 200 characters" },
                                }}
                            />
                        </FormGroup>

                        {/*
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ width: '50%' }}>
                                <FormGroup name='for_sims'>
                                    <Label for='for_sims' style={{ display: 'flex', alignItems: 'center' }}>
                                        <Toggle
                                            id="for_sims"
                                            checked={watch('for_sims')}
                                            onChange={(e) => setValue('for_sims', e.target.checked)}
                                            icons={true}
                                            aria-label="Toggle for SIMs"
                                        />
                                        <span style={{ marginLeft: '10px' }}>
                                            { watch('for_sims') ? 'Image is used for SIMs' : 'Image is not used for SIMs' }
                                        </span>
                                    </Label>
                                </FormGroup>
                            </div>
                            <div style={{ width: '50%' }}>
                                <FormGroup name='active'>
                                    <Label for='active' style={{ display: 'flex', alignItems: 'center' }}>
                                        <Toggle
                                            id="active"
                                            checked={watch('active')}
                                            onChange={(e) => setValue('active', e.target.checked)}
                                            icons={true}
                                            aria-label="Toggle photo active"
                                        />
                                        <span style={{ marginLeft: '10px' }}>
                                            { watch('active') ? 'Image is active' : 'Image is not active' }
                                        </span>
                                    </Label>
                                </FormGroup>
                            </div>
                        </div>
                        */}
                    </ModalBody>

                    <ModalFooter>
                        <FormGroup className="d-flex flex-row justify-content-end gap-2 w-100 m-0">
                            <Button className='w-100' color='secondary' onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <RSubmitButton className='w-100' color='primary'>
                                Save
                            </RSubmitButton>
                        </FormGroup>
                    </ModalFooter>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default EditDataModal;
