import React, { useEffect, useMemo, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { RichSelect as Select, Input } from 'components/react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Nav, NavItem, NavLink } from 'reactstrap';
import { useClient } from 'hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from "react-query";
import { toBase64 } from 'components/editors/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle, faTimes, faTimesCircle, faPencilAlt, faFolder, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NotificationManager } from 'react-notifications';
import { ImageComponent, ThumbnailCaption } from 'Pages/ViewPhotographerJobs/DividedGallery';
import GalleryReadonly from 'Pages/ViewPhotographerJobs/GalleryReadonly';

const SkylabModal = ({ show, setShow, photographerDownloadList, selectedJob, selectedImages }) => {
	const client = useClient();
	const navigate = useNavigate();

	const methods = useForm({});
	const { watch, register, setValue, getValues } = methods;

	const [profiles, setProfiles] = useState([])

	useQuery('get_profiles', async () => client.get(`skylab/get_profiles`).then((resp) => {
		const profiles = resp.data;
		setProfiles(profiles);
	}),
	{
		refetchOnWindowFocus: false,
		enabled: show
	})

	const { refetch: getIndividualProfile } = useQuery('get_individual_profile',
	async () => client.get(`skylab/get_profiles`, { params: { id: selected_profile.id } }).then((resp) => {
		setValue('selected_profile', resp.data)
	}), {
		refetchOnWindowFocus: false,
		enabled: false
	})

	const selected_profile = watch("selected_profile")

	const newBackgroundImage = watch("replace_background_file")

	// Check to see if selected images contain NEF images.
	// TODO: change this to a more abstract "raw" later.
	const containsNEF = useMemo(() => {
		return Object.values(selectedImages).some(image => 
			image.path.split('.').pop().toLowerCase() === 'nef'
		);
	}, [selectedImages]);

	useEffect(() => {
		if (selected_profile != null && selected_profile != undefined) {
			getIndividualProfile()
		}
	}, [selected_profile])

	async function onSubmit() {
		const data = {
			job: selectedJob.id,
			profile: selected_profile,
		}

		if (Object.keys(selectedImages).length > 0) {
			data['selected_images'] = Object.keys(selectedImages)
		} else {
			data['photographer_id'] = getValues('photographer').id
		}

		if (newBackgroundImage && getValues('replace_background_file')[0]) {
			const encoded_background_image = await toBase64(getValues('replace_background_file')[0])
			data['background_image'] = encoded_background_image
		}

		client
			.post(`skylab/process`, data)
			.then((resp) => {
				NotificationManager.success(`Skylab job has been created successfully`);
				// console.log(resp);
				navigate(`/viewworkflow/${resp.data.id}`)
			})
			.catch((e) => {
				console.error(e)
				NotificationManager.error('There was an issue with this request');
				return null;
			});
	}

	return (
		<Modal isOpen={show} className="wide-modal">
			<ModalBody>

				<FormProvider {...methods}>
					<Row>
						<Col>
							<FormGroup>
								<Label>Profile</Label>

								<Select
									getOptionLabel={(data) => {
										return data['name'];
									}}
									getOptionValue={(data) => {
										return data['id'];
									}}
									options={profiles}
									placeholder="Select profile"
									name="selected_profile"
									required={true}
									shouldUnregister={true}
								/>
							</FormGroup>
						</Col>
						{ Object.keys(selectedImages).length === 0 &&
							<Col>
								<FormGroup>
									<Label>Photographer</Label>

									<Select
										getOptionLabel={(data) => {
											return data['value'];
										}}
										getOptionValue={(data) => {
											return data['id'];
										}}
										options={[{ value: 'All Photographers', id: -1 }, ...photographerDownloadList]}
										placeholder="Select photographer"
										name={"photographer"}
										required={true}
										shouldUnregister={true}
									/>
								</FormGroup>
							</Col>
						}
						{selected_profile?.replaceBackground &&
							<Col>
								<FormGroup>
									<Label> Replace Background File</Label>
									<input
										type='file'
										className='form-control mb-2'
										id='files'
										{...register('replace_background_file', {
											required: true,
										})}
									/>
								</FormGroup>
							</Col>
						}

					</Row>
					<Row className='p-3'>
						{selected_profile?.replaceBackground && selected_profile?.photos && selected_profile?.photos[0] && <>
							<h3 className='mb-2'>Current Background File:</h3>
							<br />
							<img className='rounded' src={selected_profile?.photos[0]?.originalUrl} />
						</>}
					</Row>
					<Row className='p-3'>
						{newBackgroundImage && Array.from(newBackgroundImage).length > 0 && <>
							<h3 className='mb-2'>New Background File:</h3>
							<br />
							<div style={{ position: 'relative' }}>
								<img src={URL.createObjectURL(Array.from(newBackgroundImage)[0])} className='rounded mb-2' style={{ width: '100%' }} />

								<FontAwesomeIcon
									icon={faTimesCircle}
									className='mr-2 fa-3x add-button-center-circle'
									onClick={() => {
										setValue('replace_background_file', null);
									}}
									style={{ position: 'absolute', top: '4px', right: '5px', cursor: 'pointer' }}
								/>
							</div>
						</>}

					</Row>
				</FormProvider>

				{!!containsNEF && (<span className="text-danger"><i>The selection contains nef images and cannot be processed.</i></span>)}

				<GalleryReadonly
					margin={10}
					images={Object.values(selectedImages).slice(0, 20)}
					backdropClosesModal={true}
					rowHeight={400}
					className={"h-100"}
					thumbnailImageComponent={ImageComponent}
				/>
			</ModalBody>

			<ModalFooter>
				<Button size='sm' onClick={() => setShow(false)} color='primary'>
					Cancel
				</Button>
				<Button size='sm' onClick={onSubmit} color='primary' disabled={containsNEF || Object.keys(selectedImages).length === 0}>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default SkylabModal;
