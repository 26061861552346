import React, { useEffect, useMemo, useState, useRef } from 'react';
import { RFormGroup as FormGroup, RichSelect as Select, Input } from 'components/react-hook-form';
import DatePicker from 'react-datepicker';
import { useForm, FormProvider } from 'react-hook-form';
import { Alert, Row, Col, Label, Button } from 'reactstrap';
import { useClient } from 'hooks';
import { toBase64 } from 'components/editors/utils';
import { useQuery } from 'react-query';
import { Page, CheckData } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, } from '@fortawesome/free-solid-svg-icons';
import { letterOptionsABC as letterOptions } from "../components/editors/utils";
import { useNavigate } from 'react-router';
import { FetchingSpinner } from 'components/common';
import { RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { useParams } from 'react-router-dom';

// This is derived from StartNewJob and runs Process Images followed
// by GenProofCards in one step for the user.
// See https://git.owadigital.co.uk/project/fraser-portraits/fraser-portraits/-/issues/13#note_1935

const PORTAL_NAME = process.env.PORTAL_SERVER_NAME; // URL of the original app
const LAB_NAME = process.env.LAB_SERVER_NAME;
const TYPES = new Set(["image/jpg", "image/jpeg", "image/tiff"]);
const SCALE_OPTIONS = [
  { label: "100%", value: "100%" },
  { label: "50%", value: "50%" }
];

const HeaderCol = ({ children, ...props }) => (
  <Col className="text-center w-100" {...props}>
    {children}
  </Col>
);

const FieldCol = ({ children, ...props }) => (
  <Col className="d-flex justify-content-center align-items-center" {...props}>
    {children}
  </Col>
);

export const GenerateProofs = () => {
  const client = useClient();
  const navigate = useNavigate();

  const checkDataRef = useRef();

  const methods = useForm({
    defaultValues: {
      // For processed file generation:
      folders: {},
      selected_job: null,
      job_number: "",
      // use_local_files: false,
      is_group_photo: null,
      use_data: null,
      // For the proof card generation:
      proof_packs: {},
      proofs_deadline_date: null,
      shared: false,
      code: null,
      title: null,
      pack: null,
      // Deprecated:
      crop: {
        crop: false,
        format: "",
        DPI: 0,
        width: 0,
        height: 0,
        scale: null
      }
    },
    mode: 'all',
  });

  const [systemPresets, setPresets] = useState([]);
  const {setValue, reset, handleSubmit, register, getValues, watch} = methods;
  const [jobOptions, setJobOptions] = useState([]);
  const [showCropSection, setShowCropSection] = useState(false);
  const [showSchoolSection, setShowSchoolSection] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState(null)
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const use_data = watch('use_data');
  const selectedJob = watch('selected_job');
  const formFolders = watch('folders', {});
  const formProofPacks = watch('proof_packs', {}); // Indexed by letter ID
  const formProofDeadline = watch('proofs_deadline_date', {});
  const formShared = watch('shared', false);
  // const use_local_files = watch('use_local_files');
  const crop_image = watch('crop.crop');
  const [folderNamesList, setFolderNamesList] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [outputFolders, setOutputFolders] = useState([])

  // Loading indicators
  const [gettingJobFolderData, setGettingJobFolderData] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSubmittingRef = useRef()
  isSubmittingRef.current = isSubmitting

  // To get the parameter.
  const {job_id} = useParams();

  // If a job_id is passed in as a parameter, then select it immediately.
  useEffect(() => {
    if (job_id && jobOptions.length > 0) {
      const selectedJob = jobOptions.find(el => el.id == job_id);

      // Note: this is duplicated from the drop-down, so should probably
      // be pulled out to a function.
      if (selectedJob) {
        if (selectedJob?.has_data) {
          getJobData(selectedJob.id);
        } else {
          setJobData(null);
          setValue('use_data', false);
        }
        setFolderNamesList([]);
        setValue('files', null);
        setValue('selected_job', selectedJob);
        setValue('job_number', parseInt(selectedJob.job_number));
        getJobFolderData(selectedJob);
        setValue('proof_packs', {});
        setValue('proofs_deadline_date', selectedJob.proofs_deadline_date ? new Date(selectedJob.proofs_deadline_date) : null);
        setValue('shared', false);
      }
    }
  }, [job_id, jobOptions]);

  // Get the list of proof packs that can be selected.
  const {
		data: proofPackOptions,
		error: proofCardsError,
		refetch: getProofCards,
	} = useQuery('get_proof_packs', async () => client.get(`proof_card_designs`).get('data'), {
		refetchOnWindowFocus: false,
	});

  useEffect(() => {
    getLetterProofPackList();
  }, [formFolders]);

  function getLetterProofPackList() {
    if (formFolders === undefined) {
      return {};
    }

    // The unique list of selected letters for selected folders.
    let activeLetters = Object.keys(formFolders)
      .map((key) => {return {
        active: formFolders[key].include_in_upload,
        letter: formFolders[key].letter,
        to_generate: false,
        pack: null,
      }})
      .filter(value => value.active)
      .filter((value, index, array) => array.findIndex(findValue => findValue.letter.name === value.letter.name) == index);

    // Start with what we already have.
    let proofPacks = formProofPacks;

    // Add new letters to the proof pack list where needed,
    // and set to active where not active.
    activeLetters.forEach(activeElement => {
      if (proofPacks[activeElement.letter.id] == undefined) {
        proofPacks[activeElement.letter.id] = activeElement;
      } else {
        proofPacks[activeElement.letter.id].active = true;
      }
    });

    // Deactivate any letters that are no longer selected.
    Object.values(proofPacks).forEach((proofPack) => {
      let index = activeLetters.findIndex(activeLetterElement => activeLetterElement.letter.name === proofPack.letter.name);
      if (index === -1) {
        proofPacks[proofPack.letter.id].active = false;
      }
  });

    setValue('proof_packs', proofPacks);
  }
  
  const { refetch: getJobs } = useQuery(
    'get_paint_panels',
    async () => {
      client.get(`lab_job/min_list`).then((resp) => {
        setJobOptions(resp.data);
      });
      return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: getSystemPresets } = useQuery(
    'get_system_presets',
    async () => {
      client.get(`system_preset`).then((resp) => {
        setPresets(resp.data);
        return null;
      });
      return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const getJobData = async (job_id) => {
    client.get(`check_job_data?job=${job_id}`, {}).then((resp) => {
      setValue('use_data', true);
      setJobData(resp.data);
      setValue('csv_data', resp.data?.data?.map((data) => {
        return [data.first_name, data.surname, data.class_name, data.id];
      }));
      if (!!isSubmittingRef.current) {
        afterCSVOrderSubmit();
      }
    });
  };

  function letterInOutput(letter, outputFolders) {
    const res = outputFolders.some(function(el) {
      return el.folder_letter === letter.name;
    })
    return res;
  }

  const getJobFolderData = async (selectedJob) => {
    const job_id = selectedJob.id

    setGettingJobFolderData(true);

    client.get(`generate_generic_job/${job_id}/get_folder_info?folder_type=editing`, {}).then((resp) => {
      const folderNames = resp?.data?.folderNames;

      const directories = folderNames.map(e => ({
        ...e,
        name: e.name,
        path: "",
      }));

      const uniqueFolderTitles = {};

      const outputFolders = resp?.data.output_folders;

      function letterInUnique(letter) {
        const res = Object.values(uniqueFolderTitles).some(function(el) {
          return el.name === letter.name;
        })
        return res;
      }

      const folders = folderNames.reduce((obj, e, ind) => {
        {
          if (!uniqueFolderTitles[e.name]) {
            var idx = Object.keys(uniqueFolderTitles).length;
            var possible_letter = letterOptions[idx];
            // var possible_letter = letterOptions[Object.keys(uniqueFolderTitles).length];

            while (letterInOutput(possible_letter, outputFolders) || letterInUnique(possible_letter)) {
              possible_letter = letterOptions[idx];
              idx++;
            }

            uniqueFolderTitles[e.name] = possible_letter;
          }

          // if (e.files.length === 0) return obj;
          return {
            ...obj, [e.key]: {
              ...e,
              path: "",
              base64: true,
              preview: false,
              include_in_upload: true,
              blackwhite: false, // No longer used
              sepia: false, // No longer used
              crop: false, // No longer used
              crop_width: 1, // No longer used
              crop_height: 1, // No longer used
              school: false, // No longer used
              include_metadata: selectedJob?.has_data,
            }
          };
        }
      }, {}) ?? [];

      // console.log("UNIQUE NAMES: ", uniqueFolderTitles);

      for (const [key, folder] of Object.entries(folders)) {
        // console.log(folder)
        folders[key]['letter'] = uniqueFolderTitles[folder.name];
      }

      // console.log(uniqueFolderTitles)
      setValue('folders', folders);
      setFolderNamesList(directories);
      setOutputFolders(outputFolders);

      // setValue('use_local_files', Object.values(folders).length === 0);
      // console.log("folderNames", folderNames);

      if (folderNames.length === 0) {
        setError("Job has no photos uploaded from photographer.");
      } else {
        setError(null);
      };

      setGettingJobFolderData(false);
    }).catch((e) => {
      console.error('ERROR: ', e?.response?.data?.error);
      NotificationManager.error(e?.response?.data?.error);
      // setValue('use_local_files', true);
      // setValue('folders', {});
      setGettingJobFolderData(false);
      return null;
    });
  };

  const onSubmit = async ({ ...formData }) => {
    setIsSubmitting(true);

    if (checkDataRef.current != null) {
      checkDataRef.current.save();
    } else {
      afterCSVOrderSubmit();
    }
  }

  const afterCSVOrderSubmit = async () => {
    // console.log('this second bit')
    const formData = getValues();
    // var tempCrop = null;

    // if (formData['crop'] && formData['crop']['format']) {
    //   // Store the original values to allow resubmitting
    //   tempCrop = { ...formData['crop']['format'] };
    //   formData['crop']['format'] = formData['crop']['format'].name;
    // }

    const folders = Object.values(formData.folders).filter(e => e.include_in_upload);

    // Only if there are local files to actually upload
    const toUpload = await Promise.all(folders.map(async (e) => {
        return {
          ...e,
          files: [],
          letter: e.letter.name,
          crop_ratio: parseFloat(e?.crop_width) / parseFloat(e?.crop_height) || 1
        };
      // }
    }));

    const payload = {
      ...formData,
      folders: toUpload,
      crop: {
        ...formData.crop,
        scale: formData?.crop?.scale?.value
      }
    };

    // setIsSubmitting(false)
    // return

    // console.log(payload);
    // return;

    client
      .post('/generate_generic_job', { job_id: selectedJob.id, job_type: 'Generic', job_data: payload })
      .then((resp) => {
        NotificationManager.success('Job has been submitted');
        // reset()
        const id = resp.data?.id;
				navigate(!!id ? `/viewworkflow/${id}` : "/viewworkflow");
        // if (tempCrop) {
        //   formData['crop']['format'] = tempCrop;
        // }
        return null;
      })
      .catch((e) => {
        console.error('ERROR: ', e.response.data);
        NotificationManager.error('There was an issue with this request');
        // if (tempCrop) {
        //   formData['crop']['format'] = tempCrop;
        // }
        return null;
      }).finally(r => {
        setIsSubmitting(false);
      });
  };

  function getFolderFiles(folderObject) {
    // console.log('getFolderFiles: ', folderObject);
    const path = folderObject.folder_path;
    client
      .post(`generate_generic_job/folder_files_preview`, { job_id: selectedJob.id, path:path, folder_type: 'editing' })
      .then((resp) => {
        // NotificationManager.success('Proof card has been created');
        // console.log(resp);
        const previewImages = resp.data;
        setValue(`folders.${folderObject.key}.files`, previewImages);
        return null;
      });
  }

  function getFolderNameFromObject(folderObject) {
    var folder_path = folderObject.folder_path;

    let folders = folder_path.split("/");
    let upload_index = folders.indexOf("upload");

    if (folders.length > upload_index + 1) {
      return folders[upload_index + 1];
    }

    return folderObject.name;
  }

  return (
    <Page>
      <div className="font-bold text-2xl tracking-wide mb-3">
        Process Images and Generate Proof Cards
      </div>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup name="selected_job" label='Select job'>
            <Select
              name="selected_job"
              options={jobOptions}
              labelKey="school_name_with_date"
              valueKey="id"
              rules={{ required: true }}
              onChange={(selectedJob) => {
                reset({});
                if (selectedJob?.has_data) {
                  getJobData(selectedJob.id);
                } else {
                  setJobData(null);
                  setValue('use_data', false);
                }
                setFolderNamesList([]);
                // setUploadedFiles(null);
                setValue('files', null);
                // getJobFolderData();
                setValue('selected_job', selectedJob);
                setValue('job_number', parseInt(selectedJob.job_number));
                getJobFolderData(selectedJob);
                navigate(`/generateproofs/${selectedJob.id}`);
                return selectedJob;
              }}
              isLoading={jobOptions.length == 0}
            />
          </FormGroup>

          {error &&
            <Alert color="darker">
              {error}
            </Alert>}

          {selectedJob && (
            <>
              <FormGroup name="job_number" label='Job number'>
                <Input
                  type='number'
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  className='form-control'
                  name="job_number"
                  rules={{
                    required: true,
                    validate: v => !!v
                  }}
                  disabled
                />
              </FormGroup>

              <FormGroup>
                <Row key={5}>
                  <Col>
                    <Label check title="Use the file name as the metadata ID">
                      <Input name='is_group_photos' type='checkbox' /> Group photos?
                    </Label>
                  </Col>
                  <Col>
                    <Label check>
                      <Input name='use_data' type='checkbox' disabled={!selectedJob?.has_data} checked={watch('use_data') ?? selectedJob?.has_data} /> Use data
                    </Label>
                  </Col>
                </Row>
              </FormGroup>

              {(use_data === true && jobData?.data.length > 0) &&
                <>
                  <CheckData
                    ref={checkDataRef}
                    className='mb-3'
                    job={jobData}
                    setJob={setJobData}
                    refreshCallback={async (noChangeToOrdering=false) => {
                      // FIXME: noChangeToOrdering is always false.
                      if (noChangeToOrdering) {
                        afterCSVOrderSubmit();
                      } else {
                        getJobData(selectedJob.id);
                      }
                    }}
                    editCallback={()=>{window.open(`${PORTAL_NAME}/jobs/check_data/${selectedJob?.id}`, '_blank').focus()}}
                  />
                </>
              }

              <>
              {gettingJobFolderData ? (
									<>
										<div className='font-bold text-2xl tracking-wide mb-3'>
											Getting Job Folder Data <FetchingSpinner className='mx-2' isFetching={true} />{' '}
										</div>
									</>
								) : ( <>
                {Object.values(formFolders ?? {}).length > 0 ? (
                  <>
                    <div className="text-lg text-darker pt-4">Folder Settings</div>
                    <Row className='border-b py-4'>
                      <HeaderCol>
                        Folder Name
                      </HeaderCol>
                      <HeaderCol>
                        Photographer
                      </HeaderCol>
                      <HeaderCol>
                        Include In Upload
                      </HeaderCol>
                      <HeaderCol>
                        Assign Letter
                      </HeaderCol>
                      {selectedJob?.has_data ?
                        <HeaderCol>
                          Include Data
                        </HeaderCol>
                        : null}
                    </Row>

                    {folderNamesList.map((folderObject) => {
                      const folder = folderObject.key;
                      const files = formFolders?.[folder]?.files ?? [];
                      const useBase64 = formFolders?.[folder]?.base64;
                      const isPreview = watch('folders.' + folder + '.preview');
                      const rowEnabled = watch('folders.' + folder + '.include_in_upload');
                      //const hasNef = folderObject.nef.length ?? false


                      return (
                        <>
                          {/* {hasNef ? <span class="text-danger"><i>This folder contains .nef images and cannot be processed.</i></span> : null} */}
                          <Row className='py-1'>
                            
                            <FieldCol role='button' onClick={() => {
                              const value = getValues('folders.' + folder + '.preview')
                              setValue('folders.' + folder + '.preview', !value);
                              if (!value && files?.length == 0) {
                                getFolderFiles(folderObject);
                              }
                            }

                            }>
                              <div className="w-100 d-flex">
                                <FontAwesomeIcon className="" icon={isPreview ? faAngleUp : faAngleDown} size="lg" />
                                <h6 className="ml-2">{ getFolderNameFromObject(folderObject) }</h6>
                                
                              </div>
                            </FieldCol>

                            <FieldCol>
                              { folderObject.photographer }
                            </FieldCol>

                            <FieldCol>
                              <Input
                                name={`folders.${folder}.include_in_upload`}
                                rules={{ required: false }}
                                // defaultChecked={hasNef ? false : true}
                                defaultChecked={true}
                                type='checkbox'
                                // disabled={ hasNef }
                                className='form-check-input'
                                onChange={e => {
                                  setValue(`folders.${folder}.include_in_upload`, e.target.checked);
                                  if (formFolders) {
                                    if (!Object.values(formFolders).some(e => e.include_in_upload)) {
                                      setIsDisabled(true);
                                    } else {
                                      setIsDisabled(false);
                                    }
                                  }
                                  getLetterProofPackList();
                                }}
                              />
                            </FieldCol>

                            <FieldCol>
                              <Select
                                name={'folders.' + folder + '.letter'}
                                className='w-100'
                                options={letterOptions}
                                labelKey="name"
                                valueKey="id"
                                rules={{ required: true }}
                                // isDisabled={!rowEnabled || hasNef}
                                isDisabled={!rowEnabled}
                                onChange={e => {
                                  // console.log(e)

                                  if (letterInOutput(e, outputFolders)){
                                    if (window.confirm("This folder already exists in the output directory, click OK if you wish to overwrite the folder \n\n(WARNING: THIS CANNOT BE UNDONE AND MAY CAUSE ISSUES FOR JOBS THAT HAVE ALREADY BEEN RAN)")) {
                                        setValue('folders.' + folder + '.letter', e);
                                    }
                                  } else {
                                    setValue('folders.' + folder + '.letter', e);
                                    getLetterProofPackList();
                                  }
                                }}
                              />
                            </FieldCol>

                            {selectedJob?.has_data ?
                              <FieldCol>
                                <Input
                                  type='checkbox'
                                  name={'folders.' + folder + '.include_metadata'}
                                  // disabled={!rowEnabled || hasNef}
                                  disabled={!rowEnabled}
                                  className='form-check-input'
                                  // checked={hasNef ? false : watch('folders.' + folder + '.include_metadata')}
                                  checked={watch('folders.' + folder + '.include_metadata')}
                                />
                              </FieldCol>
                              : null}
                          </Row>

                          {(files?.length > 0 && !!isPreview) ? (
                            <>
                              <p>Folder Path: {folderObject.folder_path}</p>
                              <div className='inline-flex overflow-x-auto w-100'>
                                {files.map((file, idx) => {
                                  return <img key={idx} style={{ height: '250px' }} className='img-thumbnail m-2' src={useBase64 ? `data:image/jpeg;base64, ${file}` : window.URL.createObjectURL(file.file)} />
                                })}
                              </div>
                            </>)
                            : (!!isPreview) ?
                            <div className='font-bold text-m tracking-wide mb-3'>
                              Getting Image Previews <FetchingSpinner className='mx-1' isFetching={true} />{' '}
                            </div>
                             : null}
                        </>);
                    })}
                  </>) : null} </>)}
              </>

              {formProofPacks && (
                  <>
                    <div className="text-lg text-darker pt-4">Generate Proof Packs</div>

										<Row className='border-b pb-4 pt-4'>
                        <Col>
                          <FormGroup label="Proof Card Deadline Date">
                          <DatePicker
                              name="proofs_deadline_date"
                              className="form-control"
                              selected={formProofDeadline}
                              onChange={(newValue) => setValue('proofs_deadline_date', newValue)}
                              dateFormat='dd/MM/yyyy'
                            />
                        </FormGroup>
                      </Col>

                      <Col>
                        <Label check>
                          <Input defaultChecked={false} name='shared' type='checkbox' checked={watch(`shared`)} /> Shared proof
                        </Label>
                      </Col>
										</Row>

                    <Row className='border-b pb-4'>
                      <HeaderCol>
                        Assigned Letter
                      </HeaderCol>
                      {! formShared &&
                        <HeaderCol>
                          Proof Pack
                        </HeaderCol>
                      }
                      <HeaderCol>
                        Generate
                      </HeaderCol>
                    </Row>

                    {Object.values(formProofPacks).filter(value => value.active).map((proofPackObject) => {
                      return <>
                        <Row className='py-1'>
                          <FieldCol>{ proofPackObject.letter.name }</FieldCol>

                          {! formShared &&
                            <FieldCol>
                              <div className='w-100'>
                                <Select
                                  key={`proof_packs_${proofPackObject.letter.id}_pack`}
                                  name={`proof_packs.${proofPackObject.letter.id}.pack`}
                                  options={proofPackOptions}
                                  labelKey='name'
                                  valueKey='id'
                                  rules={{
                                    required: false,
                                  }}
                                  defaultValue={''}
                                />
                              </div>
                            </FieldCol>
                          }

                          <FieldCol>
                            <Input
                              key={`proof_packs_${proofPackObject.letter.id}_to_generate`}
                              type='checkbox'
                              name={`proof_packs.${proofPackObject.letter.id}.to_generate`}
                              className='form-check-input'
                              checked={watch(`proof_packs.${proofPackObject.letter.id}.to_generate`)}
                            />
                          </FieldCol>
                        </Row>
                      </>;
                    })}
                  </>
                )
              }

              {formShared && (
                <>
                  <FormGroup name='code' className='mt-3' label='Code'>
                    <Input
                      type='text'
                      className='form-control'
                      name='code'
                      rules={{
                        required: true,
                      }}
                    />
                  </FormGroup>
                  <FormGroup name='title' label='Title'>
                    <Input
                      type='text'
                      className='form-control'
                      name='title'
                      rules={{
                        required: true,
                      }}
                    />
                  </FormGroup>
                  <FormGroup name='pack' label='Print packs'>
                    <Select name='pack' labelKey='name' valueKey='id' options={proofPackOptions} rules={{ required: true }} />
                  </FormGroup>
                </>
              )}

              <Row key={4} className='align-items-center py-1'>
                <Col className="flex justify-content-end ">
                  <SubmitButton disabled={isDisabled} pending={isSubmitting} color="darker" type="submit">
                    Submit
                  </SubmitButton>
                </Col>
              </Row>
            </>
          )}
        </form>
      </FormProvider>
    </Page>
  );
};

export default GenerateProofs;
