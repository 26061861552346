import React, { useEffect, useMemo, useState, useRef } from 'react';
import { RFormGroup as FormGroup, RichSelect as Select, Input } from 'components/react-hook-form';

import { useForm, FormProvider } from 'react-hook-form';
import { Alert, Row, Col, Label, Button } from 'reactstrap';
import { useClient } from 'hooks';
import { toBase64 } from 'components/editors/utils';
import EXIF from "exif-js";
import { useQuery } from 'react-query';
import { Page, CheckData } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, } from '@fortawesome/free-solid-svg-icons';
import { letterOptionsABC as letterOptions } from "../components/editors/utils";
import { basename, dirname } from 'path';
import PendingButton from 'components/common/PendingButton';
import { useNavigate } from 'react-router';
import { FetchingSpinner } from 'components/common';
import { PhotographerUploadModal } from './PhotographerUploadModal';
import { RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { useParams } from 'react-router-dom';

/**
 * Legacy component no longer used.
 */

const PORTAL_NAME = process.env.PORTAL_SERVER_NAME;
const LAB_NAME = process.env.LAB_SERVER_NAME;
const TYPES = new Set(["image/jpg", "image/jpeg", "image/tiff"]);
const SCALE_OPTIONS = [
  { label: "100%", value: "100%" },
  { label: "50%", value: "50%" }
];

const HeaderCol = ({ children, ...props }) => (
  <Col className="text-center w-100" {...props}>
    {children}
  </Col>
);

const FieldCol = ({ children, ...props }) => (
  <Col className="d-flex justify-content-center align-items-center" {...props}>
    {children}
  </Col>
);

const readEXIF = async (file) => {
  let text = null;
  let exifData = await new Promise(resolve =>
    EXIF.getData(file, function () {
      resolve(EXIF.getAllTags(this));
    }
    ));
  if (exifData) {
    const asciiArr = exifData.UserComment;

    if (asciiArr) {
      // Remove leading zeroes
      while (asciiArr.indexOf(0) === 0) {
        asciiArr.shift()
      }
      text = String.fromCharCode(...asciiArr);
    }

  }

  return text ? { file: file, metadata: text } : { file: file };
};

export const StartNewJob = () => {
  const client = useClient();
  const navigate = useNavigate();

  const checkDataRef = useRef();

  const methods = useForm({
    defaultValues: {
      folders: {},
      selected_job: null,
      job_number: "",
      // use_local_files: false,
      is_group_photo: null,
      use_data: null,
      crop: {
        crop: false,
        format: "",
        DPI: 0,
        width: 0,
        height: 0,
        scale: null
      }
    },
    mode: 'all',
  });

  const [systemPresets, setPresets] = useState([]);
  const {setValue, reset, handleSubmit, register, getValues, watch} = methods;
  const [jobOptions, setJobOptions] = useState([]);
  const [showCropSection, setShowCropSection] = useState(false);
  const [showSchoolSection, setShowSchoolSection] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState(null)
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] =  useState(false);
  const use_data = watch('use_data');
  const selectedJob = watch('selected_job');
  const formFolders = watch('folders');
  // const use_local_files = watch('use_local_files');
  const crop_image = watch('crop.crop');
  const [folderNamesList, setFolderNamesList] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [outputFolders, setOutputFolders] = useState([])

  // Loading indicators
  const [gettingJobFolderData, setGettingJobFolderData] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSubmittingRef = useRef()
  isSubmittingRef.current = isSubmitting

  // Show Modal State
	const [showUploadConfirm, setShowUploadConfirm] = useState(false);

  // To get the parameter.
  const {job_id} = useParams();

  // If a job_id is passed in as a parameter, then select it immediately.
  useEffect(() => {
    if (job_id && jobOptions.length > 0) {
      const selectedJob = jobOptions.find(el => el.id == job_id);

      // Note: this is duplicated from the drop-down, so should probably
      // be pulled out to a function.
      if (selectedJob) {
        if (selectedJob?.has_data) {
          getJobData(selectedJob.id);
        } else {
          setJobData(null);
          setValue('use_data', false);
        }
        setFolderNamesList([]);
        setValue('files', null);
        setValue('selected_job', selectedJob);
        setValue('job_number', parseInt(selectedJob.job_number));
        getJobFolderData(selectedJob);
      }
    }
  }, [job_id, jobOptions]);
  

  // useEffect(() => {
  //   async function fn() {
  //     if (!uploadedFiles) return;
  //     const directories = Array.from(new Set(uploadedFiles.map(e => dirname(e.file.webkitRelativePath)))).map(e => ({
  //       name: basename(e),
  //       path: e
  //     }));

  //     const folders = directories.reduce((obj, item, ind) => {
  //       return {
  //         ...obj, [item.path]: {
  //           files: uploadedFiles.filter(file => {
  //             return basename(dirname(file.file.webkitRelativePath)) === item.name && TYPES.has(file.file.type);
  //           }),
  //           path: item.path,
  //           name: item.name,
  //           include_in_upload: true,
  //           blackwhite: false,
  //           sepia: false,
  //           crop: false,
  //           crop_width: 1,
  //           crop_height: 1,
  //           school: false,
  //           include_metadata: false,
  //           letter: ind < 26 ? { id: ind, name: letterOptions[ind].name } : "",
  //         }
  //       }
  //     }, {});

  //     setValue('folders', folders);
  //     setFolderNamesList(directories);
  //     setError(null);
  //   }
  //   fn();
  // }, [uploadedFiles]);

  const fileTypes = [
    { id: 0, name: 'JPEG' },
    { id: 1, name: 'BMP' },
    { id: 2, name: 'PNG' },
  ];

  const { refetch: getJobs } = useQuery(
    'get_paint_panels',
    async () => {
      client.get(`lab_job/min_list`).then((resp) => {
        setJobOptions(resp.data);
      });
      return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: getSystemPresets } = useQuery(
    'get_system_presets',
    async () => {
      client.get(`system_preset`).then((resp) => {
        setPresets(resp.data);
        return null;
      });
      return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const getJobData = async (job_id) => {
    client.get(`check_job_data?job=${job_id}`, {}).then((resp) => {
      setValue('use_data', true);
      setJobData(resp.data);
      setValue('csv_data', resp.data?.data?.map((data) => {
        return [data.first_name, data.surname, data.class_name, data.id]
      }));
      if (!!isSubmittingRef.current){
        afterCSVOrderSubmit()
      }
    });
  };

  function letterInOutput(letter, outputFolders) {
    const res = outputFolders.some(function(el) {
      return el.folder_letter === letter.name;
    })
    return res;
  }

  const getJobFolderData = async (selectedJob) => {
    // const job_id = selected_job?.id
    // const photographer_id = getValues('photographer')?.id
    // if (!job_id || !photographer_id) {
    //   NotificationManager.error('Please select a job and a photographer');
    //   return
    // }

    const job_id = selectedJob.id

    setGettingJobFolderData(true);

    client.get(`generate_generic_job/${job_id}/get_folder_info?folder_type=editing`, {}).then((resp) => {
      const folderNames = resp?.data?.folderNames;

      const directories = folderNames.map(e => ({
        ...e,
        name: e.name,
        path: "",
      }));

      const uniqueFolderTitles = {}

      const outputFolders = resp?.data.output_folders
      // console.log("outputFolders: ",  outputFolders)

      function letterInUnique(letter) {
        const res = Object.values(uniqueFolderTitles).some(function(el) {
          return el.name === letter.name;
        })
        return res;
      }

      const folders = folderNames.reduce((obj, e, ind) => {
        {
          if (!uniqueFolderTitles[e.name]) {
            var idx = Object.keys(uniqueFolderTitles).length
            var possible_letter = letterOptions[idx]
            // var possible_letter = letterOptions[Object.keys(uniqueFolderTitles).length]

            while (letterInOutput(possible_letter, outputFolders) || letterInUnique(possible_letter)) {
              console.log("possible letter in output: ", possible_letter);
              possible_letter = letterOptions[idx];
              idx++;
            }

            uniqueFolderTitles[e.name] = possible_letter;
          }
          // if (e.files.length === 0) return obj;
          return {
            ...obj, [e.key]: {
              ...e,
              path: "",
              base64: true,
              preview: false,
              include_in_upload: true,
              blackwhite: false,
              sepia: false,
              crop: false,
              crop_width: 1,
              crop_height: 1,
              school: false,
              include_metadata: selectedJob?.has_data,
            }
          };
        }
      }, {}) ?? [];

      console.log("UNIQUE NAMES: ", uniqueFolderTitles);

      for (const [key, folder] of Object.entries(folders)) {
        console.log(folder)
        folders[key]['letter'] = uniqueFolderTitles[folder.name];
      }

      // console.log(uniqueFolderTitles)
      setValue('folders', folders);
      setFolderNamesList(directories);
      setOutputFolders(outputFolders);

      // setValue('use_local_files', Object.values(folders).length === 0);
      console.log("folderNames", folderNames);
      if (folderNames.length === 0) {
        setError("Job has no photos uploaded from photographer. Please upload the files here instead.")
      } else {
        setError(null);
      };
      setGettingJobFolderData(false);
    }).catch((e) => {
      console.error('ERROR: ', e?.response?.data?.error);
      NotificationManager.error(e?.response?.data?.error);
      // setValue('use_local_files', true);
      // setValue('folders', {});
      setGettingJobFolderData(false);
      return null;
    });
  };

  const onSubmit = async ({ ...formData }) => {
    setIsSubmitting(true);
    if(checkDataRef.current != null){
      checkDataRef.current.save()
    } else {
      afterCSVOrderSubmit()
    }
  }

  const afterCSVOrderSubmit = async () => {
    console.log('this second bit')
    const formData = getValues()

    var tempCrop = null
    if (formData['crop'] && formData['crop']['format']) {
      // Store the original values to allow resubmitting
      tempCrop = { ...formData['crop']['format'] }
      formData['crop']['format'] = formData['crop']['format'].name
    }

    const folders = Object.values(formData.folders).filter(e => e.include_in_upload);

    // Only if there are local files to actually upload
    const toUpload = await Promise.all(folders.map(async (e) => {
      // if (!e.base64) {
      //   return {
      //     ...e,
      //     files: await Promise.all(e.files.map(async (f) => {
      //       const ret = await toBase64(f.file);
      //       return {
      //         ...f,
      //         name: f.file.name,
      //         path: f.file.webkitRelativePath,
      //         file: ret
      //       };
      //     })),
      //     letter: e.letter.name,
      //     crop_ratio: parseFloat(e?.crop_width) / parseFloat(e?.crop_height) || 1
      //   };
      // }
      // else {
        return {
          ...e,
          files: [],
          letter: e.letter.name,
          crop_ratio: parseFloat(e?.crop_width) / parseFloat(e?.crop_height) || 1
        };
      // }
    }));

    const payload = {
      ...formData,
      folders: toUpload,
      crop: {
        ...formData.crop,
        scale: formData?.crop?.scale?.value
      }
    };

    // setIsSubmitting(false)
    // return
    client
      .post('/generate_generic_job', { job_id: selectedJob.id, job_type: 'Generic', job_data: payload })
      .then((resp) => {
        NotificationManager.success('Job has been submitted');
        // reset()
        const id = resp.data?.id;
				navigate(!!id ? `/viewworkflow/${id}` : "/viewworkflow");
        if (tempCrop) {
          formData['crop']['format'] = tempCrop
        }
        return null;
      })
      .catch((e) => {
        console.error('ERROR: ', e.response.data);
        NotificationManager.error('There was an issue with this request');
        if (tempCrop) {
          formData['crop']['format'] = tempCrop
        }
        return null;
      }).finally(r => {
        setIsSubmitting(false);
      });
  };

  function getFolderFiles(folderObject) {
    console.log('getFolderFiles: ', folderObject);
    const path = folderObject.folder_path
    client
    .post(`generate_generic_job/folder_files_preview`, { job_id: selectedJob.id, path:path, folder_type: 'editing' })
    .then((resp) => {
      // NotificationManager.success('Proof card has been created');
      console.log('getFolderFiles API response', resp);
      const previewImages = resp.data;
      // setValue(`folders.${folderObject.key}.files`, previewImages.files);
      setValue(`folders.${folderObject.key}.files`, previewImages);
      return null;
    })
  }

  function getFolderNameFromObject(folderObject) {
    var folder_path = folderObject.folder_path

    let folders = folder_path.split("/")
    let upload_index = folders.indexOf("upload")

    if (folders.length > upload_index + 1) {
      return folders[upload_index + 1]
    }

    return folderObject.name
  }



  return (
    <Page>
      <div className="font-bold text-2xl tracking-wide mb-3">
        Process Job Images
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup name="selected_job" label='Select job'>
            <Select
              name="selected_job"
              options={jobOptions}
              labelKey="school_name_with_date"
              valueKey="id"
              rules={{ required: true }}
              onChange={(selectedJob) => {
                reset({})
                if (selectedJob?.has_data) {
                  getJobData(selectedJob.id);
                } else {
                  setJobData(null);
                  setValue('use_data', false);
                }
                setFolderNamesList([]);
                // setUploadedFiles(null);
                setValue('files', null);
                // getJobFolderData();
                setValue('selected_job', selectedJob);
                setValue('job_number', parseInt(selectedJob.job_number));
                getJobFolderData(selectedJob);
                navigate(`/startnewjob/${selectedJob.id}`);
                return selectedJob;
              }}
              isLoading={jobOptions.length == 0}
            />
          </FormGroup>

          {error &&
            <Alert color="darker">
              {error}
            </Alert>}

          {selectedJob && (
            <>
              <FormGroup name="job_number" label='Job number'>
                <Input
                  type='number'
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  className='form-control'
                  name="job_number"
                  rules={{
                    required: true,
                    validate: v => !!v
                  }}
                  disabled
                />
              </FormGroup>

              <FormGroup>
                <Button
                    color='primary'
                    onClick={()=>{setShowUploadConfirm(true)}}
                  >
                    Upload Files
                </Button>
              </FormGroup>

              <FormGroup>
                <Row key={5}>
                  <Col>
                    <Label check>
                      <Input name='is_group_photos' type='checkbox' /> Group photos?
                    </Label>
                  </Col>
                  <Col>
                    <Label check>
                      <Input name='use_data' type='checkbox' disabled={!selectedJob?.has_data} checked={watch('use_data') ?? selectedJob?.has_data} /> Use data
                    </Label>
                  </Col>
                </Row>
              </FormGroup>

              {(use_data === true && jobData?.data.length > 0) &&
                <>
                  <CheckData
                    ref={checkDataRef}
                    className='mb-3'
                    job={jobData}
                    setJob={setJobData}
                    refreshCallback={async (noChangeToOrdering=false) => {
                      // FIXME: noChangeToOrdering is always false.
                      if (noChangeToOrdering) {
                        afterCSVOrderSubmit();
                      } else {
                        getJobData(selectedJob.id);
                      }
                    }}
                    editCallback={()=>{window.open(`${PORTAL_NAME}/jobs/check_data/${selectedJob?.id}`, '_blank').focus()}}
                  />
                </>
              }

              <>
              {gettingJobFolderData ? (
									<>
										<div className='font-bold text-2xl tracking-wide mb-3'>
											Getting Job Folder Data <FetchingSpinner className='mx-2' isFetching={true} />{' '}
										</div>
									</>
								) : ( <>
                {Object.values(formFolders ?? {}).length > 0 ? (
                  <>
                    <div className="text-lg text-darker">Folder Settings</div>
                    <Row className='border-b py-2.5'>
                      <HeaderCol>
                        Folder Name
                      </HeaderCol>
                      <HeaderCol>
                        Include In Upload
                      </HeaderCol>
                      <HeaderCol>
                        Assign Letter
                      </HeaderCol>
                      {/*<HeaderCol>
                        Grayscale Copy
                      </HeaderCol>
                      <HeaderCol>
                        Sepia Copy
                      </HeaderCol>
                      <HeaderCol>
                        Crop
                      </HeaderCol>*/}
                      <HeaderCol>
                        Use For School CD
                      </HeaderCol>
                      {selectedJob?.has_data ?
                        <HeaderCol>
                          Include Data
                        </HeaderCol>
                        : null}
                    </Row>

                    {folderNamesList.map((folderObject) => {
                      const folder = folderObject.key
                      const files = formFolders?.[folder]?.files ?? [];
                      const useBase64 = formFolders?.[folder]?.base64;
                      const isPreview = watch('folders.' + folder + '.preview');
                      const rowEnabled = watch('folders.' + folder + '.include_in_upload');

                      return (
                        <>

                          <Row className='py-1'>
                            <FieldCol role='button' onClick={() => {
                              const value = getValues('folders.' + folder + '.preview')
                              setValue('folders.' + folder + '.preview', !value);
                              if (!value && files?.length == 0) {
                                getFolderFiles(folderObject)
                              }
                            }

                            }>
                              <div className="w-100 d-flex">
                                <FontAwesomeIcon className="" icon={isPreview ? faAngleUp : faAngleDown} size="lg" />
                                {/* <FontAwesomeIcon icon="fa-light fa-chevron-down" /> */}


                                {/* <span className="fa-light fa-chevron-down" /> */}

                                <h6 className="ml-2">{`(${folderObject.photographer})  ${getFolderNameFromObject(folderObject)}`} </h6>
                              </div>
                            </FieldCol>

                            <FieldCol>
                              <Input
                                name={`folders.${folder}.include_in_upload`}
                                rules={{ required: false }}
                                defaultChecked={true}
                                type='checkbox'
                                className='form-check-input'
                                onChange={e => {
                                  setValue(`folders.${folder}.include_in_upload`, e.target.checked);
                                  if (formFolders) {
                                    if (!Object.values(formFolders).some(e => e.include_in_upload)) {
                                      setIsDisabled(true);
                                    } else {
                                      setIsDisabled(false);
                                    }
                                  }
                                }}
                              />
                            </FieldCol>

                            <FieldCol>
                              <Select
                                name={'folders.' + folder + '.letter'}
                                className='w-100'
                                options={letterOptions}
                                labelKey="name"
                                valueKey="id"
                                rules={{ required: true }}
                                isDisabled={!rowEnabled}
                                onChange={e => {
                                  // console.log(e)

                                  if (letterInOutput(e, outputFolders)){
                                    // alert("YOU CANT HAVE THIS ONE")
                                    if (window.confirm("This folder already exists in the output directory, click OK if you wish to overwrite the folder \n\n(WARNING: THIS CANNOT BE UNDONE AND MAY CAUSE ISSUES FOR JOBS THAT HAVE ALREADY BEEN RAN)")) {
                                        setValue('folders.' + folder + '.letter', e)
                                      }
                                  } else {
                                    setValue('folders.' + folder + '.letter', e)
                                  }

                                }}
                              />
                            </FieldCol>

                            {/*<FieldCol>
                              <Input
                                type='checkbox'
                                name={'folders.' + folder + '.blackwhite'}
                                defaultValue={false}
                                className='form-check-input'
                                disabled={!rowEnabled}
                              />
                            </FieldCol>*/}

                            {/*<FieldCol>
                              <Input
                                type='checkbox'
                                name={'folders.' + folder + '.sepia'}
                                defaultValue={false}
                                disabled={!rowEnabled}
                                className='form-check-input'
                              />
                            </FieldCol>*/}

                            {/*<FieldCol>
                              <Input
                                type='checkbox'
                                name={'folders.' + folder + '.crop'}
                                defaultValue={false}
                                onChange={(value) => {
                                  setValue('folders.' + folder + '.crop', value.target.checked);
                                  if (value.target.checked) setShowCropSection(true);
                                  else {
                                    if (!Object.values(formFolders).some(e => e.crop)) {
                                      setShowCropSection(false);
                                    }
                                  }
                                }}
                                setShowSchoolSection
                                disabled={!rowEnabled}
                                className='form-check-input'
                              />
                            </FieldCol>*/}

                            <FieldCol>
                              <Input
                                type='checkbox'
                                name={'folders.' + folder + '.school'}
                                onChange={(value) => {
                                  setValue('folders.' + folder + '.school', value.target.checked);
                                  if (value.target.checked) {
                                    setShowSchoolSection(true);
                                  } else {
                                    if (!Object.values(formFolders).some(e => e.school)) {
                                      setShowSchoolSection(false);
                                      setValue('crop.crop', false);
                                    }
                                  }
                                }}
                                defaultValue={false}
                                disabled={!rowEnabled}
                                className='form-check-input'
                              />
                            </FieldCol>

                            {selectedJob?.has_data ?
                              <FieldCol>
                                <Input
                                  type='checkbox'
                                  name={'folders.' + folder + '.include_metadata'}
                                  disabled={!rowEnabled}
                                  className='form-check-input'
                                  checked={watch('folders.' + folder + '.include_metadata')}

                                />
                              </FieldCol>
                              : null}
                          </Row>
                          {(files?.length > 0 && !!isPreview) ? (
                            <>
                              <p>Folder Path: {folderObject.folder_path}</p>
                              <div className='inline-flex overflow-x-auto w-100'>
                                {files.map((file, idx) => {
                                  return <img key={idx} style={{ height: '250px' }} className='img-thumbnail m-2' src={useBase64 ? `data:image/jpeg;base64, ${file}` : window.URL.createObjectURL(file.file)} />
                                })}
                              </div>
                            </>)
                            : (!!isPreview) ?
                            <div className='font-bold text-m tracking-wide mb-3'>
                              Getting Image Previews <FetchingSpinner className='mx-1' isFetching={true} />{' '}
                            </div>
                             : null}
                        </>);
                    })}
                  </>) : null} </>)}

                {showSchoolSection && (
                  <div className="py-3">
                    <div className="text-lg text-darker pb-2.5">
                      Output Settings (For School CD)
                    </div>
                    <FormGroup name="preset" label="Preset">
                      <Select
                        isClearable={true}
                        className='w-100'
                        options={systemPresets}
                        labelKey="preset_name"
                        valueKey="id"
                        name="preset"
                        rules={{ required: false }}
                        onChange={(selectedPreset) => {
                          console.log(selectedPreset);
                          setValue('crop.format', selectedPreset?.preset_data?.file_type ?? "");
                          setValue('crop.dpi', selectedPreset?.preset_data?.DPI ?? selectedPreset?.preset_data?.dpi ?? 0);
                          setValue('crop.width', selectedPreset?.preset_data?.width ?? 0);
                          setValue('crop.height', selectedPreset?.preset_data?.height ?? 0);
                          setValue('crop.crop', selectedPreset?.preset_data?.crop ?? false);
                          if (selectedPreset == null) {
                            setValue('crop.scale', null);
                          }
                          setValue('preset', selectedPreset);
                        }}
                      />
                    </FormGroup>
                    
                    {/* { watch('preset') == null && */}
                      <>
                      <FormGroup name="crop.format" label="File Type">
                        <Select
                          className='w-100'
                          options={fileTypes}
                          labelKey="name"
                          valueKey="id"
                          name="crop.format"
                          rules={{ required: true }}
                        />
                      </FormGroup>
                      <FormGroup name="crop.dpi" label="DPI">
                        <Input
                          type='number'
                          className='form-control'
                          name="crop.dpi"
                          rules={{ required: true }}
                          min={0}
                          defaultValue={0}
                        />
                      </FormGroup>
                      <FormGroup name="crop.crop" className="mb-2">
                        <Label check>
                          <input name="crop.crop" {...register("crop.crop")} type='checkbox' /> Crop image
                        </Label>
                      </FormGroup>
                        {crop_image ? (
                          <>
                            <div className="text-darker py-2">Dimensions (px)</div>
                            <Row>
                              <Col>
                                <FormGroup name='crop.width' label={'Width'}>
                                  <Input
                                    type='number'
                                    name='crop.width'
                                    className='form-control'
                                    rules={{
                                      required: false,
                                      valueAsNumber: true
                                    }}
                                    min={0}
                                    defaultValue={0} />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup name='crop.height' label={'Height'}>
                                  <Input
                                    type='number'
                                    name='crop.height'
                                    className='form-control'
                                    rules={{
                                      required: false,
                                      valueAsNumber: true
                                    }}
                                    min={0}
                                    defaultValue={0} />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        ): (
                          <FormGroup name="crop.scale" label="Scale">
                            <Select
                              name="crop.scale"
                              // className='w-100'
                              options={SCALE_OPTIONS}
                              labelKey="label"
                              valueKey="value"
                              rules={{ required: true }}
                            />
                          </FormGroup>
                        )
                        }
                      </>
                    {/* } */}
                  </div>
                )

                }
                {folderNamesList && showCropSection &&
                  <div className="py-3">
                    <div className="text-lg text-darker pb-2.5">Crop Settings</div>
                    {folderNamesList.filter(e => !!formFolders?.[e.key].crop).map((folder, idx) => {
                      return (
                        <Row key={idx} className="py-1">
                          <Col md={2}>
                            {folder.name}
                          </Col>

                          <Col md={5}>
                            <FormGroup name={'folders.' + folder.key + '.crop_width'}>
                              <Input
                                type='number'
                                className='form-control w-100'
                                name={'folders.' + folder.key + '.crop_width'}
                                rules={{ required: false }}
                                min={1}
                                defaultValue={1} />
                            </FormGroup>
                          </Col>
                          <Col md={5}>
                            <FormGroup name={'folders.' + folder.key + '.crop_height'}>
                              <Input
                                type='number'
                                className='form-control w-100'
                                name={'folders.' + folder.key + '.crop_height'}
                                rules={{ required: false }}
                                min={1}
                                defaultValue={1} />
                            </FormGroup>
                          </Col>
                        </Row>
                      );
                    })
                    }
                  </div>}
              </>
              <Row key={4} className='align-items-center py-1'>
                <Col className="flex justify-content-end ">
                  <SubmitButton disabled={isDisabled} pending={isSubmitting} color="darker" type="submit">
                    Submit
                  </SubmitButton>
                </Col>
              </Row>
            </>
          )}
        </form>
      </FormProvider>
      
			<PhotographerUploadModal jobId={selectedJob?.id}  afterUploadSuccess={()=>{getJobFolderData(selectedJob)}} showUploadConfirm={showUploadConfirm} setShowUploadConfirm={setShowUploadConfirm} />

    </Page>
  );
};

export default StartNewJob;
