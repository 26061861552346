import React, { useState, useEffect, useRef, useMemo } from "react";
import { useQuery } from 'react-query';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { RFormGroup as FormGroup, Select, Input,  RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { Pagination, Table, Search, useListQuery, useListTable, TableContextProvider, useClientPaginatedRowSelectTable } from 'components/common/Tables';
import { toBase64 } from 'components/editors/utils';
// import { Slide } from 'react-slideshow-image';
import { NotificationManager } from 'react-notifications';
import { FetchingSpinner } from 'components/common';
import { useClient } from "hooks";
import { Page } from "components/common";
import { useNavigate } from "react-router";

import {Gallery} from 'react-grid-gallery';

const modalColumns = [
	// {
	// 	Header: 'Student name',
	// 	accessor: 'full_name',
	// },
	// {
	// 	Header: 'Photographer',
	// 	accessor: 'photographer__name',
	// },
	// {
	// 	Header: 'Class name',
	// 	accessor: 'class_name',
	// },
	// {
	// 	Header: 'Metadata',
	// 	accessor: 'metadata',
	// },
	// {
	// 	Header: "Photo",
	// 	accessor: "data",
	// 	disableSortBy: true,
	// 	Cell: (cellProps) => {
	// 		let photo = cellProps.row.values.data ?? null;
	// 		if (!photo) {
	// 			return null;
	// 		}
	// 		return (
	// 			<>
	// 				<img
	// 					className='rounded'
	// 					style={{ width: '175px', height: '175px', objectFit: 'cover' }}
	// 					src={`data:image/jpeg;base64, ${photo}`}

	// 				/>

	// 			</>
	// 		)
	// 	}
	// },
	// {
	// 	Header: "Details",
	// 	accessor: "num_images",
	// 	disableSortBy: true,
	// 	Cell: (cellProps) => {
	// 		let num_images = cellProps.row.values.num_images ?? null;
	// 		if (!num_images) {
	// 			return null;
	// 		}
	// 		return (
	// 			<>
	// 				<p>Total Images: {num_images}</p>

	// 				<p className="mt-2">{"(Click for more info)"}</p>
	// 			</>
	// 		)
	// 	}
	// },
];

export const ProcessOrders = () => {
  const client = useClient();
  const [orders, setOrders] = useState({})

  const [showSummaryConfirm, setShowSummaryConfirm] = useState(false)
  const [showExtraOrderConfirm, setShowExtraOrderConfirm] = useState(false)
  const [showExtraOrder, setShowExtraOrder] = useState(false)



  const [selectedPanels, setSelectedPanels] = useState(null)

  const [addSummary, setAddSummary] = useState(false);
  const [addExtraOrder, setAddExtraOrder] = useState(false);

  const navigate = useNavigate();

  useQuery(
    'get_pending_orders',
    async () => {
      client.get(`pending_orders`).then((data) => {
        setOrders(data.data)
      });
      return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );


  const SummaryConfirmModal = ({ show, setShow }) => {

    function handleClick(choice){
      setAddSummary(choice);
      setShow(false);
      console.log(selectedPanels);

      const name = selectedPanels.name;
      console.log(['delivery', 'reprocessed'].includes(name))

      if (!['delivery', 'reprocessed'].includes(name)){
        // this is an order that has an order id
        setSelectedPanels(selectedPanels)
        setShowExtraOrderConfirm(true)
      } else {
        // submit the orders with the choice of adding order summary
        handleCreatePanels(selectedPanels, choice)
      }

    }

    return (
      <Modal isOpen={show}>
        <ModalBody>
          <h3>Add a summary sheet to folder?</h3>
        </ModalBody>

        <ModalFooter>
          <Button className='mt-2 ml-3' color="primary" onClick={()=>{handleClick(true)}}>
              Yes
            </Button>
            <Button className='mt-2 ml-3' color="primary" onClick={()=>{handleClick(false)}}>
              No
            </Button>
        </ModalFooter>
      </Modal>
    );
  };



  const ExtraOrderConfirmModal = ({ show, setShow }) => {

    function handleClick(choice){
      setAddExtraOrder(choice)
      setShow(false)
      setShowExtraOrder(choice);

      if(!choice){
        handleCreatePanels(selectedPanels, addSummary)
      }
    }


    return (
      <Modal isOpen={show}>
        <ModalBody>
          <h3>Add additional orders to this job? </h3>
        </ModalBody>

        <ModalFooter>
          <Button className='mt-2 ml-3' color="primary" onClick={()=>{handleClick(true)}}>
              Yes
            </Button>
            <Button className='mt-2 ml-3' color="primary" onClick={()=>{handleClick(false)}}>
              No
            </Button>
        </ModalFooter>
      </Modal>
    );
  };


  const ExtraOrderModal = ({ show, setShow }) => {


    const methods = useForm({
      defaultValues: {
        panels:[
          {
            all_selected: false,
            selected_images: {},
            packs: []
          }
        ]
      },
      mode: 'all',
    });

    const { watch, setValue, register, getValues, handleSubmit } = methods;

    async function handleSubmitPress(){
      const panels = getValues('panels');
      const panelsToMake = [];
      for (const panel of panels) {
        const packs = panel.packs
        packs.forEach((pack, index) => {
          if (pack.quantity > 0) {
            for (const [key, image] of Object.entries(panel.selected_images)) {
              const notes = pack.notes ?? ''
              panelsToMake.push({
                file: image.rel_path,
                pack: printPacks[index].id,
                pack_name: printPacks[index].description,
                quantity: pack.quantity,
                price: printPacks[index].price,
                pose: image.pose,
                notes: notes
              })
            }
          }
        })
      }
      console.log("this un: ", panelsToMake)
      handleCreatePanels(selectedPanels, addSummary, panelsToMake)
    }


    function handleCancel(){
      setAddExtraOrder(false)
      setShow(false)
    }


    const {
      data: printPacks,
      refetch: getPrintPacks,
    } = useQuery('get_print_packs', async () => client.get(`get_job_packs/${selectedPanels?.job_id}`).get('data'), {
      refetchOnWindowFocus: false,
      enabled: false,
    });


    useEffect(() => {
      if (selectedPanels){
        // getProofPacks()
        // refetchTableData()
        getPrintPacks()
      }
    }, [show])

    function addEmptyPanel(){
      const panels = getValues('panels')
      panels.push({
        all_selected: false,
        selected_images: {},
        packs: []
      })
      setValue('panels', panels)
    }

    return (
      <Modal isOpen={show} className="wide-modal">
        <ModalBody>
          <FormProvider {...methods}>
              {true && (<>
                {watch("panels").map((panel, idx) => <div key={idx}>
                  <ExtraOrderPanel idx={idx} methods={methods} printPacks={printPacks}/>
                </div>)}


                <Row className='ml-0 mr-0 flex justify-content-center'>
                  <Button color='primary' onClick={addEmptyPanel}>
                    Add Panel
                  </Button>
                </Row>
              </>)}
              {/* </form> */}
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Button className='mt-2 ml-3' color="secondary" onClick={()=>{handleCancel()}}>
              Cancel
            </Button>
          <SubmitButton className='mt-2 ml-3' color="primary" onClick={()=>handleSubmitPress()}>
              Submit
            </SubmitButton>
        </ModalFooter>
      </Modal>
    );
  };


  const ExtraOrderPanel = ({ idx, methods, printPacks }) => {

    const galleryRef = useRef([]);
    const [data, setData] = useState([]);
    const [allSelected, setAllSelected] = useState(false);

    const { watch, setValue, register, getValues } = methods;

    const queryParams = useMemo(() => {
			let v = { };
      //https://localhost:3001/api/photographer_file_upload/gallery/5984?offset=0&limit=10&ordering=class_name,+surname,+photographer,+file_name,+date&gallery_tab=Individuals&group=GALLERY&folderType=editing
      // v = { folderType:"editing", gallery_tab: "Individuals", group: "GALLERY", ordering: "class_name, surname, photographer, file_name, date", ...v }
			return v;
		}, []);

    const table = useListTable(
			{
				columns: modalColumns,
				data,
				initialState: {
					pageSize: 10,
				},
				query_params: queryParams,

			},
		);


    const { isFetching: isPreviewFetching, refetch: refetchTableData } = useListQuery({
			setData: (data) => {
				setData(data.map((image, index) => ({
          src:`data:image/jpeg;base64, ${image.thumbnail}`,
					path: image.file_field,
          rel_path: image.rel_path,
          pose: image.pose,
          thumbnailCaption:
             <Row className="ml-0">
                File Number: {image.base_name.split(".")[0]}
            </Row>

				})))
      },
      // setData,
			table: table,
			url: `generate_panels/${selectedPanels?.job_id}/gallery_images`,
			query_arguments: { enabled: selectedPanels, refetchOnWindowFocus: false },
			query_params: queryParams,
			refresh_token: queryParams,
		});

    function handleImageSelect(data, setData, idx){
      return (e) => {
        data[e]['isSelected'] = !data[e].isSelected ?? true;
        const images = getValues(`panels.${idx}.selected_images`)
        console.log(images)
        setData([...data])
        const image = data[e]
        if (image['isSelected']) {
            images[image.path] = image
        } else {
            delete images[image.path]
        }
        setValue(`panels.${idx}.selected_images`, images)
      }
    }

    function selectAllImagesInGallery(){
      const selected = !allSelected
      const selectedImages = getValues(`panels.${idx}.selected_images`)
      data.forEach((image) => {
        image['isSelected'] = selected;
        if (selected) {
          selectedImages[image.path] = image
        } else {
          delete selectedImages[image.path]
        }
      })
      setData(data);
      setAllSelected(selected)
      setValue(`panels.${idx}.all_selected`, selected)
      setValue(`panels.${idx}.selected_images`, selectedImages)
    }


    return(
      <>
        <TableContextProvider table={table}>
          <Row>
            <Col>
              <Search placeholder='Search' />
            </Col>
            {/* <Col md={4}>
              <ReactSelect options={photographerList} onChange={(x) => setPhotographerFilter(x?.value)} isClearable placeholder="Select photographer" />
            </Col> */}
            <Col md={2}>
              <Button className="border-2 w-100 border-blue-500 bg-white text-black" onClick={selectAllImagesInGallery} >{allSelected ? "Unselect All" : "Select All"}</Button>
            </Col>
          </Row>
        <Pagination />
        {(!!isPreviewFetching) && (
          <Row className='ml-2 mt-2 justify-content-center align-content-center d-flex'>
            <p>Loading previews</p> <FetchingSpinner className='mx-2' isFetching={isPreviewFetching} />
          </Row>
        )}
        {/* <Row style={{height: '500px'}}> */}
          <Gallery
            passRef={galleryRef}
            margin={10}
            // images={watch('panels.${idx}.gallery_images')}
            images={data}

            backdropClosesModal={true}
            rowHeight={250}
            className={"h-100"}
            showLightboxThumbnails
            setImages={setData}
            selectedImages={watch(`panels.${idx}.selected_images`)}
            setSelectedImages={(e)=>{
              console.log(e)
              setValue(`panels.${idx}.selected_images`, e)
            }}
            onSelect={handleImageSelect(data, setData, idx)}
            onClick={handleImageSelect(data, setData, idx)}
            showData = {false}
          />
        {/* </Row> */}
      </TableContextProvider>


      {watch(`panels[${idx}].packs`).map((pack, index) =>  {
        const quantity = pack.quantity
        if (quantity > 0) {
          return (<div key={index}>
            <small className="text-muted">{`${printPacks[index].name} x ${quantity}`}</small>
            <br/>
          </div>)
        }
      })}


      <FormGroup name={`print_packs`} label='Print Packs' className="mt-3">
        {printPacks && printPacks.map((pack, index) => {
          return (
            <Row key={index} className="mb-2">
              <Col className="flex align-items-center">{pack.name} </Col>
              <Col>
                <input
                    type='number'
                    className='form-control'
                    {...register(`panels.${idx}.packs.${index}.quantity`, {
                      required: false,
                      valueAsNumber: true,
                    })}
                    min={0}
                    max={100}
                    defaultValue={0}
                  />
              </Col>
              <Col>
              <Input
                type='text'
                className='form-control'
                name={`panels.${idx}.packs.${index}.notes`}
                placeholder='Notes'
                rules={{
                  required: false,
                }}
              />
              </Col>
            </Row>
          )
         })}
        </FormGroup>
      </>
    )
  }


  const handleCreatePanels = (value, addSummary, newOrders = []) => {

    console.log(value);

    value['add_summary'] = addSummary
    console.log('Add summary: ', addSummary)

    const job_data = {
      name: value.name,
      orders: value,
      add_summary: addSummary,
      job_id: selectedPanels?.job_id
    }

    console.log('orders', 'orders')

    if (newOrders.length > 0) {
      job_data['new_orders'] = newOrders
    }

    console.log("Final Job_data: ", job_data)

    client
      .post('/generate_panels', { job_type: 'PANELS_ONLINE', job_data: job_data })
      .then((resp) => {
        NotificationManager.success('Panel has been created');
        const id = resp.data?.id;
				navigate(!!id ? `/viewworkflow/${id}` : "/viewworkflow");
        return null;
      })
      .catch((e) => {
        console.error('ERROR: ', e.response.data);
        NotificationManager.error('There was an issue with this request');
        return null;
      });
  }

  return (
    <>
      <Page>
        <div className="font-bold text-2xl tracking-wide mb-3">
          Process Orders <FetchingSpinner className="mx-2" isFetching={Object.keys(orders).length === 0} />
        </div>
        {Object.keys(orders).length > 0 &&
          <>
            <Row className="border-b pb-1 pt-1">
              <Col md={6}>
                <h6>School</h6>
              </Col>
              <Col md={3}>
                <h6>No. Online Orders</h6>
              </Col>
              <Col md={3}>

              </Col>
            </Row>
            {Object.values(orders).map((e, idx) => (
              <>
              <Row key={idx} className="mt-2">
                <Col md={6}>
                  <p>{e.name}</p>
                </Col>
                <Col md={3}>
                  <p>{e.data.length}</p>
                </Col>
                <Col md={3}>
                  <Button className="w-100" onClick={() => { setShowSummaryConfirm(true); setSelectedPanels(e) }}> Create Panels</Button>
                </Col>
              </Row>
              {console.log("E: ", e, e.job_id)}
                {e.job_id === -1 || e.job_id === -2? <>
                  <p className="ml-4"> orders in {e.name}; </p>
                  {Object.values(e.data).map((d, idx2) => (
                    <Row key={idx} className="ml-5">
                      <p>- {d.order_number ?? "No order number found"}</p>
                    </Row>
                  ))}

                </>:<></>}
              </>
            ))}
          </>
        }
      </Page>

      <SummaryConfirmModal show={showSummaryConfirm} setShow={setShowSummaryConfirm}/>
      <ExtraOrderConfirmModal show={showExtraOrderConfirm} setShow={setShowExtraOrderConfirm}/>
      <ExtraOrderModal show={showExtraOrder} setShow={setShowExtraOrder}/>
    </>
  );
};

export default ProcessOrders;
