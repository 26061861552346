import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Gallery, ThumbnailImageProps } from 'react-grid-gallery';
import { RSubmitButton as SubmitButton, RFormGroup as FormGroup } from 'components/react-hook-form';
import { Row, Col, Button} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faCheckCircle, faTimes, faTimesCircle, faPencilAlt, faFolder, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useInViewport } from 'react-in-viewport';
import { useClient } from 'hooks';
import "react-toggle/style.css"
import { values } from 'lodash';

/**
 * TODO: move this to photo-frontend/src/Pages/ViewPhotographerJobs/ as
 * that is the only page it is used. Then divide the components here up
 * into separate files, as they are going to get more complex.
 * 
 * The "divided" gallery uses Gallery to render photos, and groups and
 * orders the photos with sub-headings.
 */

export const ImageComponent = (props) => {

    const imageRef = useRef();
    
    const {
        inViewport,
        enterCount,
        leaveCount,
    } = useInViewport(
        imageRef,
        {}, // options
        { disconnectOnLeave: false }, // config
        props,
    );
 
    const [entered, setEntered] = useState(false);
    const [width, setWidth] = useState(false);
    const [height, setHeight] = useState(false);
    const [realWidth, setRealWidth] = useState(false);
    const [realHeight, setRealHeight] = useState(false);
    const [fileExtension, setFileExtension] = useState(false);
 
    const client = useClient();

    // const transparentPixel = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    const [currentRefreshKey, setCurrentRefreshKey] = useState(props.item.refreshKey || 0);

    // const [cropProcessed, setCropProcessed] = useState(false);
    const [currentCropArea, setCurrentCropArea] = useState(props.item.cropArea);

    // Calculations on image and crop widths and heights.
    const calculateCropDimensions = (cropAreaName, imgWidth, imgHeight) => {
        if (!cropAreaName || cropAreaName === 'None') {
            return { width: false, height: false, realWidth: imgWidth, realHeight: imgHeight };
        }
        
        const aspectRatios = {
            '5x7': 5 / 7,
            '8x10': 8 / 10,
            '12x5': 12 / 5,
            '12x10': 12 / 10,
        };
        
        const aspectRatio = aspectRatios[cropAreaName];
        
        if (!aspectRatio) {
            return { width: false, height: false, realWidth: imgWidth, realHeight: imgHeight };
        }
        
        let calculatedRealWidth, calculatedRealHeight;
        
        // Scale if needed
        if (imgHeight < 400) {
            calculatedRealWidth = 400 * imgWidth / imgHeight;
            calculatedRealHeight = 400;
        } else {
            calculatedRealWidth = imgWidth;
            calculatedRealHeight = imgHeight;
        }
        
        const widthT = calculatedRealHeight * aspectRatio;
        const heightT = calculatedRealWidth / aspectRatio;
        
        // Check if height is one pixel out
        const heightDiff = calculatedRealHeight - heightT;
        const finalHeight = heightDiff === 1 ? heightT + 1 : heightT;
        
        return {
            width: widthT,
            height: finalHeight,
            realWidth: calculatedRealWidth,
            realHeight: calculatedRealHeight
        };
    };

    // Load the image from the server.

    const loadImage = () => {
        client
            .get(`/photographer_job_view/${props.item.job_id}/get_gallery_thumbnail`, { params: { path: props.item.path }})
            .then((resp) => {
                // Set the image.
                imageRef.current.src = 'data:image/jpeg;base64,' + resp.data.image;

                // Remove the spinner.
                // console.log('props', props.item.id);
                // props.item.nano = null;
  
                setFileExtension(resp.data.name.split('.').pop().toLowerCase());

                // Calculate crop dimensions using the helper function
                const dimensions = calculateCropDimensions(
                    props.item.cropArea,
                    resp.data.width,
                    resp.data.height
                );

                setWidth(dimensions.width);
                setHeight(dimensions.height);
                setRealWidth(dimensions.realWidth);
                setRealHeight(dimensions.realHeight);
 
                setEntered(true);
            })
            .catch((e) => {
                if (e.response.status == 404) {
                    imageRef.current.src = '/missing-image.svg';
                } else {
                    imageRef.current.src = '/broken-image.svg';
                }
            });
    };

    // A reload function that can be called via props.
    const reloadImage = () => {
        if (imageRef.current) {
            // Reset the image and state
            imageRef.current.src = '';
            setEntered(false);
            // Load the image again
            loadImage();
        }
    };

    // Make the reload function available through props.
    if (props.onRefMount) {
        props.onRefMount({ reloadImage });
    }

    // Watch for changes to the refreshKey and reload when it changes.
    // The refresh key is just for updates to the photo image.
    useEffect(() => {
        // Only reload if the key has changed (not on initial render)
        if (props.item.refreshKey !== undefined && props.item.refreshKey !== currentRefreshKey) {
            // console.log('Refresh key changed for image', props.item.id, 'from', currentRefreshKey, 'to', props.item.refreshKey);
            
            // Update our tracking of the current key
            setCurrentRefreshKey(props.item.refreshKey);
            
            // Reset the image state
            if (imageRef.current) {
                imageRef.current.src = '';
            }
            setEntered(false);
            
            // Reload the image
            loadImage();
        }
    }, [props.item.refreshKey]);


    useEffect(() => {
        // Only run when the image is already loaded and crop area changes
        if (
            entered && 
            imageRef.current && 
            imageRef.current.complete && 
            props.item.cropArea !== currentCropArea
        ) {
            // console.log('Updating crop area for loaded image:', props.item.path);
            
            // Track the new crop area
            setCurrentCropArea(props.item.cropArea);
            
            // Get the current image dimensions
            const currentWidth = realWidth || imageRef.current.naturalWidth;
            const currentHeight = realHeight || imageRef.current.naturalHeight;
            
            // If we have dimensions, recalculate the crop using the helper function
            if (currentWidth && currentHeight) {
                const dimensions = calculateCropDimensions(
                    props.item.cropArea,
                    currentWidth,
                    currentHeight
                );
                
                setWidth(dimensions.width);
                setHeight(dimensions.height);
                setRealWidth(dimensions.realWidth);
                setRealHeight(dimensions.realHeight);
                
                // Make sure file extension is set if not already
                if (!fileExtension) {
                    setFileExtension(props.item.path.split('.').pop().toLowerCase());
                }
            }
        }
    }, [props.item.cropArea, entered]);
    
    // On the image entering the viewport for the first time,
    // we want to load the image from the API.
    // We need to keep state on whether we have entered.
  
    if (inViewport && enterCount == 1 && entered == false && imageRef.current.getAttribute('src') == '') {
        setEntered(true);
        loadImage();
    }
 
    // Don't set cropping area if it's an NEF image
    if (fileExtension != 'nef' && width && props.item.cropArea && props.item.cropArea !== 'None') {
        // Image with crop marks displayed.
        return (<>
            <div data-testid="container" className="reactEasyCrop_Container_default" style={{'width': realWidth, 'height': realHeight}}>
                <img ref={imageRef} {...props.imageProps} />
                <div className="reactEasyCrop_CropArea_default reactEasyCrop_CropArea_default" style={{'width': width, 'height': height}}></div>
            </div>
        </>);
    } else {
        if (props.item.cropArea && props.item.cropArea != 'None') {
            // Crop marks selected, but an image type that does not support cropping.
            return (<>
                <div data-testid="container" className="reactEasyCrop_Container_default no-cursor">
                    <img ref={imageRef} {...props.imageProps} />
                </div>
            </>);
        } else {
            // Image of any type with no crop marks.
            return (<>
                <div data-testid="container">
                    <img ref={imageRef} {...props.imageProps} />
                </div>
            </>);
        }
    }
}

/**
 * The caption below each thumbnail image.
 * Includes some edit actions.
 */
export const ThumbnailCaption = ({
    photoRecord,
    allowEdit,
    showData = true,
    handleEditData,
    handleImageCrop,
    cropArea,
    handleActiveToggle,
    handleForSimsToggle,
}) => {
    // console.log('photoRecord', photoRecord);
    return (
        <Col>
            {(!!photoRecord?.metadata || !!photoRecord?.class_name) && showData && (
                <>
                    <Row>
                        Name: {photoRecord.full_name}
                    </Row>
                    <Row>
                        Metadata: {photoRecord.metadata}
                        { photoRecord.for_sims ? (<span className="pl-2"><FontAwesomeIcon icon={faIdCard} size="lg" /></span>) : "" }
                    </Row>
                    <Row>
                        Class Name: {photoRecord.class_name}
                    </Row>
                    <Row>
                        Photographer: {photoRecord.photographer_name}
                    </Row>
                </>
            )}

            <Row>
                File Name: { photoRecord?.path?.replace(/^.*[\\\/]/, '') }
                { !photoRecord.active && <span title="Photo is inactive" class="text-red-500">&nbsp;<FontAwesomeIcon icon={faTimesCircle} /></span>}
            </Row>

            <Row className="extra-padding" style={{display: "flex", alignItems: "center", gap: "10px", marginTop: "0.5em"}}>{allowEdit && <>
                    <SubmitButton
                        className={'activeButton'}
                        onClick={(e) => handleActiveToggle(photoRecord.id, e.target.checked)}
                        color={photoRecord.active ? 'success' : 'danger'}
                    >
                        {photoRecord.active ? 'Active' : 'Inactive'}
                    </SubmitButton>

                    {photoRecord.path.split('.').pop().toLowerCase() != 'nef' && <>
                        <SubmitButton
                            className={'cropButton'}
                            onClick={handleImageCrop(photoRecord, 'rotate')}
                            color='primary'
                        >
                            Rotate
                        </SubmitButton>

                        {!!cropArea && cropArea != 'None'  &&
                            <SubmitButton
                                className={'active-crop'}
                                onClick={handleImageCrop(photoRecord, 'crop')}
                                color='primary'
                            >
                                Crop
                            </SubmitButton>
                        }
                    </>}

                    <Button
                        onClick={(e) => handleForSimsToggle(photoRecord.id, e.target.checked)}
                        color={photoRecord.for_sims ? 'info' : ''}
                        style={{ borderColor: "#dee2e6" }}
                        outline={!photoRecord.for_sims}
                    >
                        {photoRecord.for_sims ? 'SIMS 🗸' : 'SIMS 𐄂'}
                    </Button>
                </>}
            </Row>

            {photoRecord?.proof_cards.length > 0 && (
                <Row>
                    Proof Card: { photoRecord?.proof_cards.map((proof_card) => (<span className="pl-1">
                        <a href={proof_card.url}>{proof_card.code}</a>
                    </span>)) }
                </Row>
            )}
        </Col>
    );
}

/**
 * images is an array of all image records to display in this gallery
 * selectedImages are the image records that have been selected in this gallery by the user, keyed by abs pathname (!)
 */
export const DividedGallery = ({
    images,
    setImages,
    selectedImages,
    setSelectedImages,
    passRef,
    showData = true,
    cropArea,
    selectedCropImage,
    setSelectedCropImage,
    showCropConfirm,
    setShowCropConfirm,
    setImageEditType,
    entered,
    setEntered,
    enteredCrop,
    setEnteredCrop,
    onActiveToggle,
    onForSimsToggle,
    onEditPhotoData,
    onEditData,
    folderType,
    allowEdit = true,
    ...props
}) => {
    // const galleryGroups = []
    var miniImages = []
    let prevKeyVal = null

    // State to track individual image refresh counters by ID.
    // Any image ID added to this list, or having its value incremented,
    // will be refreshed.
    const [imageRefreshKeys, setImageRefreshKeys] = useState({});

    const client = useClient();

    useEffect(() => {
        // Update all images with the new cropArea.
        if (images && images.length > 0) {
            images.forEach(img => {
                img.cropArea = cropArea;
            });
            
            // Replace the current images with the updated ones
            setImages([...images]);
        }
    }, [cropArea]);

    // Refresh a single image by ID.
    const refreshImageById = (imageId) => {
        // alert('By id ' + imageId);
        setImageRefreshKeys(prev => ({
            ...prev,
            [imageId]: (prev[imageId] || 0) + 1
        }));
    };

    // Make this function available to parent components.
    if (passRef && passRef.current) {
        passRef.current.refreshImageById = refreshImageById;
    }

    function handleImageCrop(image, type) {
        return (e) => {
            // This is set for the processing list.

            // TODO: abstract this. The image is a raw image, and the server should flag that in the data.
            // We ultimately want to get rid of the image paths in this page. Image paths have *no*
            // business being in the front end. Each image has an ID identifying it in the database.

            var fileExtension = image.path.split('.').pop();

            if (fileExtension.toLowerCase() != 'nef') {
                client
                    .get(`/photographer_job_view/${image.job_id}/get_full_photo`, { params: { path: image.path }})
                    .then((resp) => {
                        var imageOutput = 'data:image/jpeg;base64,' + resp.data.image;
                        
                        // Convert base64 string into a blob as large images were not loading in firefox
                        const byteString = atob(resp.data.image);
                        const ab = new ArrayBuffer(byteString.length);
                        const ia = new Uint8Array(ab);
                    
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                    
                        const blob = new Blob([ab], {type: 'image/jpeg'});
                        var imageUrl = URL.createObjectURL(blob);

                        // console.log(imageUrl);

                        setSelectedCropImage({image: image, width: resp.data.width, height: resp.data.height, imageOutput: imageUrl})
                        setShowCropConfirm(true);
                        setImageEditType(type);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
		}
    }

    function handleImageSelect(setAllImages, cropArea, galleryImages) {
		return (index) => {
            if (cropArea && cropArea != 'None') {
                return;
            }

            // The index is the image index within this gallery instance.
            // From this point we use the image ID.

            const image = galleryImages[index];

            // Toggle the selected state of the image in the master image list.

            setAllImages(prevImages => 
                prevImages.map(loopImage => 
                    loopImage.id === image.id
                    ? { ...loopImage, isSelected: !loopImage.isSelected ?? true }
                    : loopImage
                )
            );

            // Add or remove this image from the selected images.
            // Note the state for image has not yet changed, so isSelected will be the
            // opposite of what we want to do with the image at this point.
            // TODO: index by the image ID rather than the path, but check what needs to be updated for that.

            if (! image['isSelected']) {
                selectedImages[image.path] = image;
            } else {
                delete selectedImages[image.path];
            }

            // This is set for the processing list.

            setSelectedImages({...selectedImages});
		}
	};

    // The user clicks the data edit button to edit the
    // student data against a metadata item.
    function handleEditData(metadata) {
        if (metadata) {
            onEditData(metadata);
        }
    }

    // The "active" toggle switch has been changed.
    function handleActiveToggle(id, e) {
        if (id) {
            onActiveToggle(id);
        }
    };

    // The "for SIMs" toggle switch has been changed.
    function handleForSimsToggle(id, e) {
        if (id) {
            onForSimsToggle(id);
        }
    };

    /**
     * Creates a single gallery, with a title, from a selection of images.
     */
    function createGalleryRow(selectionImages, key, metadata) {
        // Create an appropriate title for this group.

        let title = '';

        // Get a unique list of full names for the title.

        const names = [...new Set(
            selectionImages.map(image => (image.full_name ? image.full_name.trim() : '')).filter(full_name => full_name !== "")
        )];

        if (names.length == 1 || names.length == 2) {
            title = names.join(', ');
        } else if (names.length > 2) {
            title = names.length + ' names';
        }

        if (title == '') {
            // No subject names, so try the photographer names.

            const photographerNames = [... new Set(
                selectionImages.map(image => (image.photographer_name ? image.photographer_name.trim() : '')).filter(photographer_name => photographer_name !== "")
            )];

            if (photographerNames.length == 1) {
                title = 'Photographer: ' + photographerNames.join(', ');
            } else if (photographerNames.length == 2) {
                title = 'Photographers: ' + photographerNames.join(', ');
            } else if (photographerNames.length > 2) {
                title = photographerNames.length + ' photographers';
            }
        }

        if (title == '') {
            // Try the metadata.

            const allMetadata = [... new Set(
                selectionImages.map(image => (image.metadata ? image.metadata.trim() : '')).filter(metadata => metadata !== "")
            )];

            if (allMetadata.length > 0 && allMetadata.length <= 3) {
                title = 'Metadata: ' + allMetadata.join(', ');
            } else if (allMetadata.length > 2) {
                title = allMetadata.length + ' mixed metadata';
            }
        }

        return (
            <Row className="mt-5">
                <Col>
                    <h1 style={{paddingBottom: "4px", display: "flex", alignItems: "center", gap: "10px"}} className='text-2xl border-b-4 border-cool-gray-500'>
                        <span>{ title}</span>

                        {!!metadata && folderType == 'editing' && <Button
                            style={{ fontSize: "0.6em", borderColor: "#dee2e6", color: "#495057"  }}
                            color="light"
                            outline
                            size="sm"
                            onClick={(e) => handleEditData(metadata)}
                            title="Edit the student name and class for this metadata"
                        >
                            Edit '{metadata}'
                        </Button>}
                    </h1>

                    <Gallery
                        key={key}
                        images={selectionImages.map(img => ({
                            ...img,
                            // Add a refresh key to each image using the imageRefreshKeys state.
                            // Images not being refreshed get a key of 0.
                            refreshKey: imageRefreshKeys[img.id] || 0
                        }))}
                        // ref={el => passRef.current[offset] = el}
                        onSelect={handleImageSelect(setImages, cropArea, selectionImages)}
                        onClick={handleImageSelect(setImages, cropArea, selectionImages)}
                        thumbnailImageComponent={ImageComponent}
                        thumbnailCaption={{ position: "overlay", hideElement: false }}
                        {...props}
                    />
                </Col>
            </Row>
        )
    }

    // let index = 0;

    // Loop over all available images to organize them into galleries.
    // Organize the images into galleries based on metadata or photographer name.

    // Sort the images as they would be ordered in the original database query.
    // We can later add filters here, so we don't need to go back to the server
    // when changing filters.
    const sortedImages = [...images].sort((imgA, imgB) => {
        // First compare by class_name
        const classNameComparison = imgA.class_name.localeCompare(imgB.class_name);
        if (classNameComparison !== 0) return classNameComparison;

        // If class_name is the same, compare by surname
        const surnameComparison = imgA.surname.localeCompare(imgB.surname);
        if (surnameComparison !== 0) return surnameComparison;

        // If surname is the same, compare by first_name
        const firstNameComparison = imgA.first_name.localeCompare(imgB.first_name);
        if (firstNameComparison !== 0) return firstNameComparison;

        // If first_name is the same, compare by photographer
        if (imgA.photographer_name != undefined && imgB.photographer_name != undefined) {
            const photographerComparison = imgA.photographer_name.localeCompare(imgB.photographer_name);
            if (photographerComparison !== 0) return photographerComparison;
        }

        // If photographer is the same, compare by file_name
        return imgA.file_name.localeCompare(imgB.file_name);
    });

    // Group the images by their key
    let qCount = 0;
    const imageGroups = {};

    // Group images by their key. The key is based on metadata or photographer name + q-suffix group.
    sortedImages.forEach(imageObject => {
        // Count q_suffix images so we can detect a break.
        if (imageObject.q_suffix) {
            qCount += 1;
        }
        
        // Create a key for this image
        const keyVal = imageObject.metadata
            ? imageObject.metadata
            : imageObject.photographer_name + "|" + qCount;
        
        // Initialize the group if it doesn't exist yet
        if (!imageGroups[keyVal]) {
            imageGroups[keyVal] = {
                metadata: imageObject.metadata,
                key: keyVal,
                images: []
            };
        }
        
        // Process image before adding to group
        // const processedImage = {
        //     ...imageObject,
        //     cropArea: cropArea
        // };
        imageObject.cropArea = cropArea;
        
        // Deselect images if crop area active
        if (cropArea && cropArea !== 'None') {
            // processedImage.isSelected = false;
            imageObject.isSelected = false;
        }
        
        // Add the caption to the image
        imageObject.thumbnailCaption = (
            <ThumbnailCaption
                photoRecord={imageObject}
                showData={showData}
                allowEdit={allowEdit && folderType === 'editing'}
                handleEditData={handleEditData}
                handleImageCrop={handleImageCrop}
                handleActiveToggle={handleActiveToggle}
                handleForSimsToggle={handleForSimsToggle}
                cropArea={cropArea}
            />
        );
        
        // Add the processed image to its group
        imageGroups[keyVal].images.push(imageObject);
    });

    // Convert the groups into gallery rows.
    const galleryGroups = [];

    Object.entries(imageGroups).forEach(([key, group]) => {
        galleryGroups.push(
            createGalleryRow(
                [...group.images],
                key,
                group.metadata
            )
        );
    });

    return galleryGroups
}
