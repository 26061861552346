import React, { useRef, useState } from 'react';
import { Gallery, ThumbnailImageProps } from 'react-grid-gallery';
import { Row, Col, Button} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faCheckCircle, faTimes, faTimesCircle, faPencilAlt, faFolder, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useInViewport } from 'react-in-viewport';
import { useQuery } from "react-query";
import { useClient } from 'hooks';
import { Link } from 'react-router-dom';

export const ImageComponent = (props: ThumbnailImageProps) => {
    const imageRef = useRef();
    const {
        inViewport,
        enterCount,
        leaveCount,
    } = useInViewport(
        imageRef,
        {}, // options
        { disconnectOnLeave: false }, // config
        props
    );

    const [entered, setEntered] = useState(false);
    const client = useClient();

    // console.log('inViewport', inViewport, 'enterCount', enterCount, 'imageRef', imageRef.current);

    // On the image entering the viewport for the first time,
    // we want to load the image from the API.
    // We need to keep state on whether we have entered.

    if (inViewport && enterCount == 1 && entered == false && imageRef.current.getAttribute('src') == '') {
        setEntered(true);

        // Fetch the image. Caching it would be great for when the gallery filter is changed.

        client
            .get(`/photographer_job_view/${props.item.job_id}/get_gallery_thumbnail`, { params: { path: props.item.path }})
            .then((resp) => {
                imageRef.current.src = 'data:image/jpeg;base64,' + resp.data.image;
                setEntered(true);
            })
            .catch((e) => {
                console.error(e);
            });
    }
    
    return <img ref={imageRef} {...props.imageProps} />;
};

export const DividedGallery = ({images, setImages, selectedImages, setSelectedImages, passRef, showData = true, ...props}) => {
    const galleryGroups = []
    var miniImages = []
    let prevKeyVal = null

    function handleImageSelect(data, setData, offset, e) {
		return (e) => {
            // This is set for display purposes.
            data[e + offset]['isSelected'] = !data[e + offset].isSelected ?? true;
            setData([...data]);

            const image = data[e + offset];

            if (image['isSelected']) {
                selectedImages[image.path] = image;
            } else {
                delete selectedImages[image.path];
            }

            // This is set for the processing list.
            setSelectedImages({...selectedImages})
		}
	}

    function createGalleryRow(selectionImages, titleContext, offset, title) {
        return (
            <Row className="mt-5">
                <Col>
                    <h1 className='text-2xl border-b-4 border-cool-gray-500'>{ title + titleContext}</h1>
                    <Gallery
                        images={ selectionImages }
                        ref={el => passRef.current[offset] = el}
                        onSelect={handleImageSelect(images, setImages, offset)}
                        onClick={handleImageSelect(images, setImages, offset)}
                        thumbnailImageComponent={ImageComponent}
                        {...props}
                    />
                </Col>
            </Row>
        )
    }

    let index = 0;
    while (index < images.length) {
        const imageObject = images[index]
        // console.log('imageObject', imageObject);

        // Each keyVal identifies a grouping.
        // Either full name (with metadata in case more then one has the same name)
        // or just metadata (grouping for all photographers) or just photographer
        // name (@todo include photographer ID in case of two with same name,
        // which will need to be passed from the back end).

        const keyVal = (imageObject.full_name !== " ")
            ? imageObject.metadata + "|" + imageObject.full_name
            : (imageObject.metadata ? imageObject.metadata : imageObject.photographer_name);

        // console.log('Image ', index, 'keyVal =', keyVal, 'group length =', miniImages.length);

        // Decide if we are going to add this image to the group.
        // Either the group is empty, or the key is the same as the last added to the group.

        const addImageToGroup = (prevKeyVal === null || keyVal === prevKeyVal);

        if (addImageToGroup) {
            // console.log("Pushing image " + index + ' to list; with for_sims=' + (imageObject.for_sims ? 'true' : 'false'))
            // console.log(imageObject)
            miniImages.push(imageObject);
            prevKeyVal = keyVal;
            index++;
        }

        // Decide if the group is complete.
        // Either this is the last image (and was added to the group, perhaps on its own),
        // or the image did not get added to the group because it belongs in the next group.

        if (index === (images.length) || !addImageToGroup) {
            // console.log('miniImages', miniImages);

            miniImages.forEach((tempImg) => {
                // const offset = index - miniImages.length
   
                tempImg['thumbnailCaption'] = (
                    <Col>
                        {(!!tempImg?.metadata || !!tempImg?.class_name) && showData && (
                            <>
                                <Row>
                                    Name: {tempImg.full_name}
                                </Row>
                                <Row>
                                    Metadata: {tempImg.metadata}
                                    { tempImg.for_sims ? (<span className="pl-2"><FontAwesomeIcon icon={faIdCard} size="lg" /></span>) : "" }
                                </Row>
                                <Row>
                                    Class Name: {tempImg.class_name}
                                </Row>
                                <Row>
                                    Photographer: {tempImg.photographer_name}
                                </Row>
                            </>
                        )}

                        <Row>
                            File Name: { tempImg?.path?.replace(/^.*[\\\/]/, '') }
                        </Row>

                        {tempImg?.proof_cards.length > 0 && (
                            <Row>
                                Proof Card: { tempImg?.proof_cards.map((proof_card) => (<span className="pl-1">
                                    <a href={proof_card.url}>{proof_card.code}</a>
                                </span>)) }
                            </Row>
                        )}

                        {/* {showData &&
                            <Row>
                                <Button color="primary" onClick={async ()=>{
                                    passRef.current[offset].openLightbox(index2)
                                }}>
                                    Preview
                                </Button>
                            </Row>
                        } */}
                    </Col>
                )
            })

            // The title comes from the first image in the group, which will share
            // title properties with all images in the group.
            // There will either be a full name, or a photographer name.

            // Now we have three groupings: named individuals, set metadata and no metadata.
            // See https://git.owadigital.co.uk/project/fraser-portraits/fraser-portraits/-/issues/38

            galleryGroups.push(
                createGalleryRow(
                    [...miniImages],
                    miniImages[0].full_name != " " ? miniImages[0].full_name : (miniImages[0].metadata ? miniImages[0].metadata : (miniImages[0].photographer_name ?? 'unknown')),
                    index - miniImages.length,
                    miniImages[0].full_name != " " ? 'Full Name: ' : (miniImages[0].metadata ? 'Metadata: ' : 'Photographer Name: ')
                )
            )

            // Reset the group for the next iteration of the image loop.

            prevKeyVal = null;
            miniImages = [];
        }
    }

    return galleryGroups
}
