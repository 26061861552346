import { useLocation, useResolvedPath } from "react-router";

export const useMatch = (to, { startsWith = false } = {}) => {
  const location = useLocation();
  const resolvedLocation = useResolvedPath(to);
  if (!startsWith) return location.pathname === resolvedLocation.pathname;
  return location.pathname.startsWith(resolvedLocation.pathname);
};

export default useMatch;
